import { EndPoints ,account} from "../constants/Enums";
import axios from "axios";

async function updateUser(
    userID:any,
    version:any,
    email:any,
    name:any,
    phone_number:any,
    address: any,
    birthdate: any,
    gender: any,
    picture: any,
    phones: [any], 
    deleted:any,
    createdAt:any,
) {
  try {
    const operationId = 17;
    const userAttributes={
        email:email,
        name:name,
        phone_number:phone_number,
        address: address,
        birthdate: birthdate,
        gender: gender,
        picture: picture,
        phones: phones,
        deleted:deleted,
        createdAt:createdAt,
      }
    
    const requestBody = {
      operationId,
      userID,
      userAttributes:userAttributes,
      version
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    const response = await axios.post(EndPoints.getCategoryData, requestBody);
    return response.data;
  } catch (error) {
    console.error("Error updating user :", error);
    throw error;
  }
}

export default updateUser;
