import {
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  useTheme
} from "@mui/material";
import MobileFriendlyOutlinedIcon from "@mui/icons-material/MobileFriendlyOutlined";
import { useEffect, useState } from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setLogin } from "../../state/index";
import { signInWithRedirect, signOut, getCurrentUser, signIn, SignInInput, fetchUserAttributes, signUp, fetchAuthSession } from "aws-amplify/auth";
import { AuthUser } from "aws-amplify/auth";

import { Link, useNavigate } from "react-router-dom";
import getUser from "../../services/getUser";

type LoginForm = {
  phoneNumber: string;
  password: string;
};

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<LoginForm>({}); // Specify the form data type
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleGoogleLogin = async () => {
    try {
      await signInWithRedirect({ provider: "Google" });
    } catch (error) {
      console.error("Error logging in with Google:", error);
    }
  };
  const handleAppleLogin = async () => {
    try {
      await signInWithRedirect({ provider: "Apple" });
    } catch (error) {
      console.error("Error logging in with Apple:", error);
    }
  };

  const handleInputBlur = (fieldName: any) => {
    trigger(fieldName);
  };

  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onSubmit = async (data: LoginForm) => {
    try {
      let input: SignInInput = {
        username: "+20" + data.phoneNumber,
        password: data.password
      }
      setLoading(true)
      await signIn(input)
      let loggedInUser = await fetchUserAttributes()
      let currentUser = await getUser(loggedInUser.sub)
      dispatch(setLogin({ user: currentUser }));
      setLoading(false)
      navigate('/')
    } catch (error) {
      console.error("Error submitting user details:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-end"
        alignContent="flex-end"
        sx={{
          textAlign: "center",
          minHeight: "100vh",
          backgroundImage: `url(../assets/DUNKIN/MenuItems/login.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingBottom: "2rem",
        }}
      >


        <Typography
          style={{
            fontSize: 20,
            fontWeight: 600,
            color: theme.palette.primary.main,
          }}
        >
          LOGIN
        </Typography>

        <Grid container sx={{ marginX: "1.5rem" }}>
          <Grid item sm={12} xs={12}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Phone Number"
              fullWidth
              {...register("phoneNumber", {
                required: "Phone number is required",
                pattern: {
                  value: /^[0-9]{11}$/,
                  message: "Invalid phone number format",
                },
              })}
              onBlur={() => handleInputBlur("phoneNumber")}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.primary.main,
                  width: "100%",
                  margin: "0.5rem auto",
                  borderColor: "#ffffff",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                  marginBottom: "1rem",
                },
              }}
            />
            {errors.phoneNumber && (
              <span style={{ color: "red" }}>{errors.phoneNumber.message}</span>
            )}
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Password"
              fullWidth
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              })}
              onBlur={() => handleInputBlur("password")}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.primary.main,
                  width: "100%",
                  margin: "0.5rem auto",
                  borderColor: "#ffffff",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                  marginBottom: "1rem",
                },
              }}
            />
            {errors.password && (
              <span style={{ color: "red" }}>{errors.password.message}</span>
            )}
            <Link
              to="/forgetPass"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <Typography
                style={{
                  color: "white",
                  fontSize: 10,
                }}
              >
                Forget Password?
              </Typography>
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          sm={12}
          xs={12}
          sx={{ marginY: "1rem", marginX: "1.5rem" }}
        >
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              font: "0.3rem",
              width: "100%",
              color: "#FFFFFF",
              background: ' linear-gradient(90deg, rgba(255,114,0,1) 0%, rgba(255,114,0,1) 46%, rgba(255,24,156,1) 85%, rgba(255,24,156,1) 100%)',
              boxShadow: "none",
              transition: "box-shadow 0.3s",
              borderRadius: "1rem",
              padding: "1rem",
              "&:hover": {
                boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                background: ' linear-gradient(90deg, rgba(255,114,0,1) 0%, rgba(255,114,0,1) 46%, rgba(255,24,156,1) 85%, rgba(255,24,156,1) 100%)',

              },
            }}
          >
            Login
          </Button>

          <Typography
            style={{
              color:'#ff189c',
            }}
          >
            Not Registered?
            <Link
              to="/register"
              style={{ textDecoration: "none",  color: '#ff6e0c' }}
            >
              Register Here
            </Link>
          </Typography>
        </Grid>

        <Grid container sx={{ marginX: "1.5rem" }}>
          <Grid item sm={4} xs={4}>
            <Divider
              sx={{
                backgroundColor: "white",
                marginTop: "0.6rem",
                height: "0.1rem",
              }}
            />
          </Grid>
          <Grid item sm={4} xs={4}>
            <Typography
              variant="body1"
              style={{
                color: '#ff6e0c'
              }}
            >
              Or Login With
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4}>
            <Divider
              sx={{
                backgroundColor: "white",
                marginTop: "0.6rem",
                height: "0.1rem",
              }}
            />
          </Grid>
        </Grid>

        {/* //Auth sign */}
        <Grid
          container
          sx={{ marginX: "1.5rem", marginTop: "1rem", alignItems: "center" }}
        >
          <Grid item sm={4} xs={4}>
            <MobileFriendlyOutlinedIcon sx={{ color: "black" }} />
          </Grid>
          <Grid item sm={4} xs={4}>
            <Button onClick={handleGoogleLogin}>
              <img src="../assets/icons8-google.png" alt="sing with google" />
            </Button>
            {/* <button onClick={() => signOut()}>Sign Out</button> */}
          </Grid>
          <Grid item sm={4} xs={4}>
            <Button onClick={handleAppleLogin}>
              <img src="../assets/icons8-apple.png" alt="sing with aplle" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default LoginPage;
