import { Box } from "@mui/material";
interface CategoryContainerProps {
  image: string;
}

const CategoryContainer = ({ image }: CategoryContainerProps) => {
  return (
    <Box sx={{ margin: "0px 5px" }}>
      <img src={image} alt=""  style={{width:"100%",height:"100%",borderRadius:"3rem",}} />
    </Box>
  );
};

export default CategoryContainer;
