import { useParams } from "react-router";
import MenuItemHeader from "../../components/menu/MenuItemHeader";
import MenuItems from "../../components/menu/MenuItems";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { Category, MenuItem } from "../../models/Category";
import { getMenuItems } from "../../services/getOperation";
import Navbar from "../../components/Nav/Navbar";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function Menu() {
  const location = useLocation();
  const selectedItemFromState = useSelector(
    (state: any) => state.selectedItem || {}
  );
  const { id } = useParams();

  const [totalPrice, setTotalPrice] = useState(0);
  const selectedItem = useSelector((state: any) => state.selectedItem || {});
  const [data, setData] = useState([]);
  const handleTotalPriceChange = (newTotalPrice: number) => {
    setTotalPrice(newTotalPrice);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <MenuItemHeader />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          color: "white",
          zIndex: 1,
          borderRadius: "5rem",
        }}
      >
        <MenuItems />
      </Grid>
      <Navbar />
    </Grid>
  );
}

export default Menu;
