import { useForm } from 'react-hook-form';
import { Typography, Grid, Box, TextField,useTheme, Button } from "@mui/material";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { SignUpInput, signUp } from "aws-amplify/auth";

type RegisterForm = {
    name: string;
    phoneNumber: string;
    birthDate: string;
    email: string;
    password: string;
    confirmPassword: string;
};

function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const [user, setUser] = useState(null);
    const [error, setError] = useState<unknown>(null);
    const [customState, setCustomState] = useState<string | null>(null);
    const { register, handleSubmit, formState: { errors }, trigger } = useForm<RegisterForm>({});
    const [showPassword, setShowPassword] = useState(false);

    const handleInputBlur = (fieldName: any) => {
        trigger(fieldName);
    };

    const onSubmit =  async (data: RegisterForm) => {
        if (data.password !== data.confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try{
            let input:SignUpInput={
                username: "+20"+data.phoneNumber,
           
                password: data.password,
                options:{
                    userAttributes:{
                        email:data.email,
                        name:data.name
                    }
                }
            }
          const user= await signUp(input)
          console.log(user)
           navigate('/login')
        }
        catch{
            setError('Error');
        }
      
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Grid container
            
                justifyContent="center"
                alignItems="flex-end"
                sx={{
                
                    padding: "1rem",
                    marginTop: '3rem'
                }}
            >
                <Typography
                    style={{
                        fontSize: 30,
                        fontWeight: 700,
                     
                        color: '#ff6e0c',

                    }}>
                    SIGN UP
                </Typography>
                <Grid item sm={12} xs={12}>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Name"
                        fullWidth
                        {...register("name", {
                            required: "Name is required",
                            minLength: {
                                value: 3,
                                message: "Name must be at least 3 characters",
                            },
                            maxLength: {
                                value: 50,
                                message: "Name must be at most 50 characters",
                            },
                        })}
                        onBlur={() => handleInputBlur("name")}
                         sx={{
                            "& .MuiInputBase-root": {
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.main,
                                width: "100%",
                                margin: "0.5rem auto",
                                borderColor: "#ffffff",
                                marginBottom: "1rem",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            },
                        }}
                    />
                    {errors.name && (
                        <span style={{ color: "red" }}>{errors.name.message}</span>
                    )}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Phone Number"
                        type='number'
                        fullWidth
                        {...register("phoneNumber", {
                            required: "Phone number is required",
                            pattern: {
                                value: /^[0-9]{11}$/,
                                message: "Invalid phone number format",
                            },
                        })}
                        onBlur={() => handleInputBlur("phoneNumber")}

                         sx={{
                            "& .MuiInputBase-root": {
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.main,
                                width: "100%",
                                margin: "0.5rem auto",
                                borderColor: "#ffffff",
                                marginBottom: "1rem",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            },
                        }}
                    />
                    {errors.phoneNumber && (
                        <span style={{ color: "red" }}>{errors.phoneNumber.message}</span>
                    )}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Birth Date"
                        fullWidth
                        type="date"
                        // inputProps={{ min: today }}
                        {...register("birthDate", {
                            required: "Birth Date is required",
                        })}
                        onBlur={() => handleInputBlur("phobirthDateneNumber")}

                         sx={{
                            "& .MuiInputBase-root": {
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.main,
                                width: "100%",
                                margin: "0.5rem auto",
                                borderColor: "#ffffff",
                                marginBottom: "1rem",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            },
                        }}
                    />
                    {errors.birthDate && (
                        <span style={{ color: "red" }}>{errors.birthDate.message}</span>
                    )}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Email"
                        fullWidth
                        type="email"
                        {...register("email", {
                            required: "email is required",
                            pattern: {
                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/,
                                message: "Invalid email format",
                            },
                        })}
                        onBlur={() => handleInputBlur("email")}

                         sx={{
                            "& .MuiInputBase-root": {
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.main,
                                width: "100%",
                                margin: "0.5rem auto",
                                borderColor: "#ffffff",
                                marginBottom: "1rem",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            },
                        }}
                    />
                    {errors.email && (
                        <span style={{ color: "red" }}>{errors.email.message}</span>
                    )}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Password"
                        fullWidth
                        type="password"
                        //  onBlur={() => handleInputBlur("password")}

                        {...register("password", {
                            minLength: {
                                value: 8,
                                message: "Password must be at least 8 characters",
                              },
                        })}
                         sx={{
                            "& .MuiInputBase-root": {
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.main,
                                width: "100%",
                                margin: "0.5rem auto",
                                borderColor: "#ffffff",
                                marginBottom: "1rem",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            },
                        }}
                    />
                    {errors.password && (
                        <span style={{ color: "red" }}>{errors.password.message}</span>
                    )}
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Confirm Password"
                        fullWidth
                        type="Password"
                    //   onBlur={() => handleInputBlur("confirmPassword")}

                        {...register("confirmPassword", {
                            required: "confirm Password is required",
                            minLength: {
                                value: 8,
                                message: "Password must be at least 8 characters",
                              },
                        })}
                         sx={{
                            "& .MuiInputBase-root": {
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.main,
                                width: "100%",
                                margin: "0.5rem auto",
                                borderColor: "#ffffff",
                                marginBottom: "1rem",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            },
                        }}
                    />
                    {errors.confirmPassword && (
                        <span style={{ color: "red" }}>{errors.confirmPassword.message}</span>
                    )}
                </Grid>
                <Grid container
                    justifyContent="center" sm={12} xs={12}
                    sx={{ marginY: '1rem' }}>
                    <Button variant="contained" type="submit"
                        sx={{
                            font: '0.3rem', width: '100%',
                            color: '#FFFFFF',
                            background:' linear-gradient(90deg, rgba(255,114,0,1) 0%, rgba(255,114,0,1) 46%, rgba(255,24,156,1) 85%, rgba(255,24,156,1) 100%)',
                            boxShadow: 'none',
                            transition: 'box-shadow 0.3s',
                            borderRadius: '1rem',
                            padding: "1rem",
                            '&:hover': {
                                boxShadow: '0px 0px 5px 2px rgb(240,126,165,0.7)',
                                background:' linear-gradient(90deg, rgba(255,114,0,1) 0%, rgba(255,114,0,1) 46%, rgba(255,24,156,1) 85%, rgba(255,24,156,1) 100%)',
                            },
                        }}>
                        REGISTER
                    </Button>

                    <Typography
                        style={{
                            color:'#ff189c',
                            marginTop: "0.5rem",
                        }}>
                        Already have an account?
                        <Link to="/login" style={{ textDecoration: 'none', color: '#ff6e0c' }}>
                            Login Now
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            {/* {error && <p style={{ color: 'red' }}>{error}</p>} Display error message */}

        </form>
    )
}

export default Register
