export const EndPoints = {
  getCategoryData:
    "https://imvujym4fqluwxrd6pwbxemvoe0ldjpe.lambda-url.us-east-2.on.aws",
};

export const Pages = {
  MENU_LIST: "menuList",
  ORDERS: "myorders",
  ORDER_DETAILS: "orderdetails",
  TRACK_ORDER: "trackOrder",

  CART: "cart",
  LANGUAGES: "languages",
  REVIEW: "review",
  LANG: "lang",
};

export const concept = {
  id: "65acbf07-1a9a-4dde-9605-a20f19753404",
};

export const Keys = {
  CONCEPT: "Concept",
  MAIN_CATEGORIES: "MainCategories",
  SUB_CATEGORIES: "SubCategories",
  CATEGORIES: "Categories",
  CART: "Cart",
};

export const OrderStatus = {
  pending: "pending",
  sent: "sent to restaurant",
  failed: "failed to sent to restaurant",
  confirmed: "confirmed",
  cancelled: "cancelled",
  pendingCancellation: "pending Cancellation",
  updated: "updated",
  acceptedByWaiter: "accepted by waiter",
  sentToKitchen: "sent To Kitchen",
  acceptedByKitchen: "accepted by kitchen",
  ready: "ready",
  outForDelivery: "out for delivery",
  delivered: "delivered",
};

export const WaiterCallingStatus = {
  pending: "pending",
  arrived: "arrived",
};

export const currency = {
  SAR: "SAR",
};

export const account = {
  accountID: "5b57fcdf-f9f8-4351-84c0-14f437e3aasb",
}

export const OrderType = "Dine In";
export const OrderTime = "now";
export const MySecretKey = "MySecretKey";
