import { Box, Grid, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
// import defaultImage from "../../assets/image1.png"

function MenuItemHeader() {
  const defaultImage = "/assets/DUNKIN/MenuItems/Bakery/(10).jpg";
  const selectedItem = useSelector((state: any) => state.selectedItem || {});

  // const backgroundImage=selectedItem.image || defaultImage;
  return ( 
    // {imageNotFound? <img src={require('../../assets/no-image-icon-15.png')} />:

    <Box sx={{background:"white" ,borderBottomRightRadius:"5px" ,borderBottomLeftRadius:"5px"}}>
       <img
              style={{
                objectFit: "contain",
                width: "100%",
                aspectRatio: "3.5/1",
                backgroundSize: "cover",
                
              }}
              src={selectedItem.image.substring(1,selectedItem.image.length+1)}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = selectedItem.image.substring(2,selectedItem.image.length+1); // Fallback to default image on error loading API image
              }}
            />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ position: "absolute", top: 20, width: "100%" }}
      >
        <Grid item>
          <Link to="/">
            <IconButton
              sx={{
                backgroundColor: "rgba(169, 169, 169, 0.55)",
                color: "rgba(64, 64, 64, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ArrowBackIosNewIcon sx={{ color: "#ff189c" }} />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MenuItemHeader;
