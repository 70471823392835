import {
  Divider,
  Grid,
  Box,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getUserOrders } from "../../services/getUserOrders";
import { setOrder } from "../../state/index";
import { capitalization, formatDate } from "../../constants/helperFunction";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function OrderDetails() {
  const location = useLocation();
  const selectedOrder = location.state?.selectedOrder || {};
  console.log("selectedOrder");
  console.log(selectedOrder);
  const theme = useTheme();
  const orders = useSelector((state: any) => state.orders);
  const defaultImage = "assets/image1.png";

  useEffect(() => {}, []);

  return (
    <Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: "1rem" }}
      >
        <Grid item sm={12} lg={12}>
          <Link to="/orders">
            <Button
              sx={{
                backgroundColor: "rgba(169, 169, 169, 0.55)",
                color: "rgba(64, 64, 64, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: " 2rem",
                height: "2rem",
                borderRadius: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <ArrowBackIosNewIcon
                sx={{ color: "#ff189c", fontSize: "1rem" }}
              />
            </Button>
          </Link>
        </Grid>
        <Grid item sm={12} lg={12} xs={12}>
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: 24,
              fontWeight: "600",
              letterSpacing: 0.96,
            }}
          >
            Order Details
          </Typography>
        </Grid>

        <Grid container sx={{ marginBottom: "3rem" }}>
          <Grid container>
            <Grid
              container
              key={selectedOrder.id}
              sx={{
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "0.3rem",
                marginY: "1rem",
                padding: "0.5rem",
                justifyContent: "flex-start",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)", // Box shadow
              }}
            >
              <Grid item sm={12} xs={12}>
                <Grid container sx={{ marginBottom: "1rem" }}>
                  {/* the image */}
                  <Grid item sm={3} xs={3}>
                    <img
                      src={selectedOrder.image || defaultImage}
                      alt={selectedOrder.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        marginTop: "0.5rem",
                        borderRadius: "1rem",
                        border: "0.1rem solid #ff189c",
                      }}
                    />
                  </Grid>
                  {/* the info */}
                  <Grid item sm={8} xs={8} sx={{ paddingLeft: "0.5rem" }}>
                    <Typography
                      style={{
                        fontSize: 30,
                        color: theme.palette.primary.main,
                        fontWeight: 400,
                      }}
                    >
                      {capitalization(
                        selectedOrder.orderedItems[0].name.substring(0, 10)
                      )}
                    </Typography>

                    <Typography
                      sx={{
                        color: selectedOrder.status ? "#ff189c" : "#FF69B4",
                      }}
                    >
                      {selectedOrder.status ? "Delivered" : "In Progress"}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 14,
                      }}
                    >
                      {formatDate(selectedOrder.createdAt)}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 14,
                      }}
                    >
                      Order ID:{" "}
                      {capitalization(selectedOrder.id.substring(0, 9))}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ borderColor: "#B3B3B3", borderWidth: "1px" }} />
              </Grid>

              {/* order address */}
              <Grid
                display="flex"
                item
                sm={12}
                xs={12}
                sx={{ padding: "1rem" }}
              >
                <Box
                  sx={{
                    color: "#ff189c",
                    zIndex: 1,
                    marginRigth: "1rem",
                  }}
                >
                  <LocationOnIcon sx={{ fontSize: "2rem" }} />
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 20,
                        color: theme.palette.primary.main,
                        fontWeight: 400,
                      }}
                    >
                      Delivery Address
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 18,
                      }}
                    >
                      {selectedOrder.userName || "no address"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 18,
                      }}
                    >
                      {selectedOrder.orderAddress || "no address"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* order summary */}
              <Grid item sm={12} xs={12} flexDirection="column">
                <Divider
                  sx={{
                    borderColor: "#B3B3B3",
                    borderWidth: "1px",
                    marginY: "0.5rem",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: 20,
                    color: theme.palette.primary.main,
                    fontWeight: 400,
                  }}
                >
                  Order Summary
                </Typography>
                <Box>
                  {selectedOrder.orderedItems.map(
                    (menuItem: any, menuItemIndex: any) => (
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        key={menuItem.id}
                        sx={{
                          borderRadius: "0.3rem",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ width: "100%" }}
                        >
                          <Typography
                            sx={{
                              color: "#B3B3B3",
                              fontSize: 18,
                            }}
                          >
                            x {menuItem.quantity} {menuItem.name}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#B3B3B3",
                              fontSize: 18,
                            }}
                          >
                            SAR {menuItem.price}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  )}
                </Box>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    borderRadius: "0.3rem",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 18,
                      }}
                    >
                      Delivery Fee
                    </Typography>
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 18,
                      }}
                    >
                      SAR {selectedOrder.deliveryFee}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 18,
                        fontWeight: 300,
                      }}
                    >
                      Total
                    </Typography>
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 18,
                        fontWeight: 300,
                      }}
                    >
                      SAR {selectedOrder.totalAmount}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* ) : (
                        // Display a message when no orders are present
                        <Grid
                            sx={{
                              borderRadius: "0.3rem",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ width: "100%" }}
                            >
                              <Typography
                                sx={{
                                  color: "#B3B3B3",
                                  fontSize: 18,
                                }}
                              >
                                x {menuItem.quantity} {menuItem.name}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#B3B3B3",
                                  fontSize: 18,
                                }}
                              >
                                SAR {menuItem.price}
                              </Typography>
                            </Box>
                        </Grid>
                    )} */}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrderDetails;
