import { PayloadAction, createSlice } from "@reduxjs/toolkit";
interface AppState {
  mode: string;
  app: any;
  user: any;
  splashScreen:any
  //categories
  categoriesState: {
    listing: any[];
  };
  mainCategoriesState: {
    listing: any[];
  };
  subCategoriesState: {
    listing: any[];
  };
  menuItems: any[];
  cart: {
    orderedItems: any;
    quantity: number;
    total: number;
  };
  table: {
    listing: any;
    tableId: any;
    encryptedID: any;
  };
  concept: {
    listing: any;
  };
  orders: any;
  currentOrder: any;
  selectedItem: any;
}
const initialState: AppState = {
  mode: "dark",
  app: null,
  user: null,
  splashScreen:true,
  //categories
  categoriesState: {
    listing: [],
  },
  mainCategoriesState: {
    listing: [],
  },
  subCategoriesState: {
    listing: [],
  },
  menuItems: [],
  cart: {
    orderedItems: null,
    quantity: 0,
    total: 0,
  },
  table: {
    listing: null,
    tableId: null,
    encryptedID: null,
  },
  concept: {
    listing: null,
  },
  orders: null,
  currentOrder: {},
  selectedItem: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMode: (state: AppState) => {
      state.mode = state.mode === "light" ? "dark" : "light";
      console.log(`the mode from state is done ${state.mode}`);
    },
    //categories
    setCategories: (state: AppState, action: PayloadAction<any>) => {
      state.categoriesState.listing = action.payload;
    },
    setMainCategories: (state: AppState, action: PayloadAction<any>) => {
      state.mainCategoriesState.listing = action.payload;
    },
    setSubCategories: (state: AppState, action: PayloadAction<any>) => {
      state.subCategoriesState.listing = action.payload;
    },

    setMenuItems: (state: AppState, action: PayloadAction<any[]>) => {
      state.categoriesState.listing = action.payload;
    },
    setLogin: (state, action) => {
      const { user } = action.payload;
      state.user = user;
      console.log(user);
    },

    //cart
    setCart: (state: AppState, action: PayloadAction<any>) => {
      state.cart.orderedItems = action.payload;
      console.log(`cart ${JSON.stringify(state.cart.orderedItems)}`);
    },
    setCartQuantity: (state: AppState, action: PayloadAction<number>) => {
      state.cart.quantity = action.payload;
      console.log(`cartQuantity ${state.cart.quantity}`);
    },
    setCartTotal: (state: AppState, action: PayloadAction<number>) => {
      state.cart.total = action.payload;
      console.log(`cartTotal ${state.cart.total}`);
    },
    emptyCart: (state: AppState) => {
      state.cart = {
        orderedItems: [],
        quantity: 0,
        total: 0,
      };
    },
    //table
    setTable: (state: AppState, action: PayloadAction<any>) => {
      state.table.listing = action.payload;
    },
    setTableId: (state: AppState, action: PayloadAction<any>) => {
      state.table.tableId = action.payload;
    },
    setEncryptedID: (state: AppState, action: PayloadAction<any>) => {
      state.table.encryptedID = action.payload;
    },
    //cart
    setConcept: (state: AppState, action: PayloadAction<any>) => {
      state.concept.listing = action.payload;
    },

    //order
    setOrder: (state: AppState, action: PayloadAction<any>) => {
      state.orders = action.payload;
    },

    // current order
    setCurrentOrder: (state: AppState, action: PayloadAction) => {
      state.currentOrder = action.payload;
    },

    //selectedItem
    setSelectedItem: (state: AppState, action: PayloadAction<any>) => {
      state.selectedItem = action.payload;
    },
  setSplashScreen: (state: AppState, action: PayloadAction<any>) => {
      state.splashScreen = action.payload;
    },
  },
});

export const {
  setMode,
  setLogin,
  setCategories,
  setMainCategories,
  setSubCategories,
  setMenuItems,
  //cart
  setCart,
  setCartQuantity,
  setCartTotal,
  emptyCart,
  //table
  setTable,
  setTableId,
  setEncryptedID,
  //concept
  setConcept,
  //order
  setOrder,
  setCurrentOrder,
  //selectedItem,
  setSelectedItem,
  setSplashScreen,
} = appSlice.actions;
export default appSlice.reducer;
