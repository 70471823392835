import {
  IconButton,
  Grid,
  Box,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getUserOrders } from "../../services/getUserOrders";
import { setOrder } from "../../state/index";
import { capitalization, formatDate } from "../../constants/helperFunction";
import Navbar from "../../components/Nav/Navbar";
import MySkeleton from "../../components/Skeleton/OrderHistorySkeleton";
import Review from "../Review/Review";

function OrderHistory() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [showReview, setShowReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [CartData, setCartData] = useState([]);
  const orders = useSelector((state: any) => state.orders);
  const user = useSelector((state: any) => state.user);
  // const userID = user.id;
  if (!user) {
    navigate("/login");
  }
  const userID = "12345678M9";
  const defaultImage = "assets/image1.png";

  const handleReviewClick = () => {
    setShowReview(true);
  };
  const closeReviewForm = () => {
    setShowReview(false);
  };

  const handleOrderItemClick = (order: any) => {
    navigate("/orderDetails", { state: { selectedOrder: order } });
    console.log("CartData", order);
  };

  //for reorder
  const handleReorderClick = () => {
    // setCartData(order);
    // navigate("/cart", { state: { CartData: order } });
  };
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getUserOrders(userID);
      setIsLoading(false);
      dispatch(setOrder(response));
    } catch (error) {
      console.log("Error fetching getOdersData:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [CartData]);

  return (
    <Grid>
      {isLoading ? (
        <MySkeleton />
      ) : (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "1rem" }}
        >
          <Grid item sm={12} lg={12}>
            <Link to="/">
              <Button
                sx={{
                  backgroundColor: "rgba(169, 169, 169, 0.55)",
                  color: "rgba(64, 64, 64, 0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: " 2rem",
                  height: "2rem",
                  borderRadius: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <ArrowBackIosNewIcon
                  sx={{ color: "#ff189c", fontSize: "1rem" }}
                />
              </Button>
            </Link>
          </Grid>
          <Grid item sm={12} lg={12} xs={12}>
            <Typography
              style={{
                color: theme.palette.primary.main,
                fontSize: 24,
                fontWeight: "600",
                letterSpacing: 0.96,
              }}
            >
              My Orders
            </Typography>
          </Grid>

          <Grid
            sx={{
              backgroundColor: theme.palette.primary.dark,
              borderRadius: "0.3rem",
              marginY: "1rem",
              justifyContent: "center",
              alignItems: "center",
              padding: "3rem 5rem",
              width: "100%",
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)", // Box shadow
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                style={{
                  textAlign: "center",
                  color: theme.palette.primary.main,
                  fontSize: 18,
                  fontFamily: "Inter",
                  marginTop: "1rem",
                }}
              >
                <WidgetsIcon sx={{ color: "#ff189c" }} /> Sorry, no orders yet.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      {!showReview && <Navbar />}
    </Grid>
  );
}

export default OrderHistory;
