import {  Grid, Typography, useTheme, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function Notifications() {
    const theme = useTheme();

    return (
        <Grid>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ padding: '1rem' }}
            >
                <Grid item sm={12} lg={12}>
                    <Link to="/">
                        <Button
                            sx={{
                                backgroundColor: 'rgba(169, 169, 169, 0.55)',
                                color: 'rgba(64, 64, 64, 0.7)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minWidth: ' 2rem',
                                height: '2rem',
                                borderRadius: '0.5rem',
                                marginBottom: '1rem',
                            }}
                        >
                            <ArrowBackIosNewIcon sx={{ color: '#ff189c', fontSize: '1rem' }} />
                        </Button>
                    </Link>
                </Grid>
                <Grid item sm={12} lg={12} xs={12}>
                    <Typography style={{
                        color: theme.palette.primary.main,
                         fontSize: 24,
                        fontWeight: '600',
                         letterSpacing: 0.96,
                    }}>
                      Notifications
                    </Typography>

                </Grid>
            </Grid>
        </Grid>


    )
}

export default Notifications
