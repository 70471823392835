import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  InputAdornment,
  useTheme,
  Grid,
  Typography,
  Button,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import { getCart } from "../../services/cartServices";
import {
  getCartItemsQuantity,
  updateOnlineCart,
  removeTypeNameFromItems,
  decrementOrRemoveItem,
  getLocalCart,
  updateOfflineCart,
  mergeLocalAndOnlineCart
} from "../../services/cartUtils";
import {
  setCart,
  setCartQuantity,
  setCartTotal,
  setCurrentOrder,
} from "../../state/index";
import { Link, useNavigate } from "react-router-dom";
import { capitalization } from "../../constants/helperFunction";
import { OrderTime, currency } from "../../constants/Enums";
import { OrderStatus, OrderType } from "../../constants/Enums";
import createOnlineOrder from "../../services/createOrder";
import EmptyCart from "./EmtyCart";

function Cart() {
  const theme = useTheme();
  // const domainURL =
  //   "https://res.cloudinary.com/dipqm0n1i/image/upload/EtihadAirways/";
  const items: any[] = useSelector(
    (state: any) => state.cart.orderedItems || []
  );
  console.log(items)
  const totalQuantity = useSelector((state: any) => state.cart.quantity || 0);
  const totalPrice = useSelector((state: any) => state.cart.total || 0);
  const themeMode = useSelector((state: any) => state.mode);
  const [notes, setNotes] = useState(Array(items.length).fill(""));
  const [disableBtns, setDisableBtns] = useState<boolean>(false);
  const itemNames = ['Cappucino', 'Latte', 'Mocha', 'Bagel Cinnamon Double Cheese', 'Bagel Cream Cheese Plain', 'Bagel Cream Cheese Oats']
  const images1 = ["./assets/DUNKIN/MenuItems/Bakery/(1).jpg", "./assets/DUNKIN/MenuItems/Bakery/(2).jpg", "./assets/DUNKIN/MenuItems/Bakery/(3).jpg",
    "./assets/DUNKIN/MenuItems/Bakery/(4).jpg", "./assets/DUNKIN/MenuItems/Bakery/(5).jpg",
    "./assets/DUNKIN/MenuItems/Bakery/(6).jpg", "./assets/DUNKIN/MenuItems/Bakery/(7).jpg",
    "./assets/DUNKIN/MenuItems/Bakery/(8).jpg", "./assets/DUNKIN/MenuItems/Bakery/(9).jpg",
    "./assets/DUNKIN/MenuItems/Bakery/(10).jpg", "./assets/DUNKIN/MenuItems/Bakery/(12).jpg", "./assets/DUNKIN/MenuItems/Bakery/(11).jpg"];
  const [cartData, setCartData] = useState<any>({});
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);
  const specialRequest = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // This must be changed after login is fixed to get the real userID
  const user = useSelector((state: any) => state.user);
  const updateCartState = (
    updatedCartItems: any,
    newQuantity: number,
    newTotalPrice: number
  ) => {
    dispatch(setCart(updatedCartItems));
    dispatch(setCartQuantity(newQuantity));
    dispatch(setCartTotal(newTotalPrice));
  };
  const setDefaultNotes = (cartItems: any) => {
    const updatedNotes = [...notes];
    for (let i = 0; i < cartItems.length; i++) {
      updatedNotes[i] = cartItems[i].notes;
      setNotes(updatedNotes);
    }
  };

  const handleWarningClose = () => {
    setWarning(false);
  };
  const handleSuccessClose = () => {
    setSuccess(false);
  };



  const handleIncrement = async (index: number) => {
    setDisableBtns(true);
    const updatedItems = [...items];
    const updatedItem = {
      ...updatedItems[index],
      quantity: updatedItems[index].quantity + 1,
      notes: notes[index],
    };
    updatedItems[index] = updatedItem;
    const newQuantity = totalQuantity + 1;
    const newTotalPrice = totalPrice + updatedItem.price;
    if (user) {
      await updateOnlineCart(updatedItems, newTotalPrice, user.id);
    } else {
      updateOfflineCart(updatedItems, newTotalPrice);
    }
    updateCartState(updatedItems, newQuantity, newTotalPrice);
    setDisableBtns(false);
  };

  const handleDecrement = async (index: number) => {
    setDisableBtns(true);
    const updatedItems = [...items];
    const updatedItem = {
      ...updatedItems[index],
      quantity: updatedItems[index].quantity - 1,
    };
    updatedItems[index] = updatedItem;
    const finalUpdatedItems = decrementOrRemoveItem(
      updatedItems,
      updatedItem,
      index
    );
    const newQuantity = totalQuantity - 1;
    const newTotalPrice = totalPrice - updatedItem.price;
    if (user) {
      await updateOnlineCart(updatedItems, newTotalPrice, user.id);
    } else {
      updateOfflineCart(updatedItems, newTotalPrice);
    }
    updateCartState(finalUpdatedItems, newQuantity, newTotalPrice);
    setDisableBtns(false);
  };
  const handleRemoveItem = async (index: number) => {
    setDisableBtns(true);
    const updatedItems = [...items];
    const item = updatedItems[index];
    updatedItems.splice(index, 1);
    const newQuantity = totalQuantity - item.quantity;
    const newTotalPrice = totalPrice - item.price * item.quantity;
    if (user) {
      await updateOnlineCart(updatedItems, newTotalPrice, user.id);
    } else {
      updateOfflineCart(updatedItems, newTotalPrice);
    }
    updateCartState(updatedItems, newQuantity, newTotalPrice);
    setDisableBtns(false);
  };
  const handleOrderPlacement = async () => {
    if (!user) {
      navigate("/login");
      return;
    }
    if (items.length < 1) {
      setWarning(true);
      return;
    }
    setDisableBtns(true);
    const orderData = {
      cartID: cartData.id,
      conceptID: cartData.conceptID,
      userID: user.id,
      userName: user.userName ? user.userName : "customer",
      orderedItems: removeTypeNameFromItems(items),
      specialRequest: specialRequest.current,
      OrderStatus: OrderStatus.pending,
      orderType: OrderType,
      orderTime: OrderTime,
      totalPrice,
      tableID: "",
      createdAt: new Date().toLocaleDateString(),
      tableName: "",
      parentOrder: "12345678",
      childrenStatus: [""],
    };
    const order = await createOnlineOrder(
      orderData.cartID,
      orderData.conceptID,
      orderData.userID,
      orderData.userName,
      // items,
      orderData.orderedItems,
      orderData.specialRequest,
      orderData.OrderStatus,
      orderData.orderType,
      orderData.orderTime,
      orderData.totalPrice,
      orderData.tableID,
      orderData.createdAt,
      orderData.tableName,
      orderData.parentOrder,
      orderData.childrenStatus
    );
    if (!order.id) {
      setWarning(true);
      setDisableBtns(false);
      return;
    }
    await updateOnlineCart([], 0, user.id);
    setSuccess(true);
    updateCartState([], 0, 0);
    dispatch(setCurrentOrder(order));
    // setTimeout(() => {
    navigate("/ordertrack");
    // }, 2000);
  };
  const handleNoteChange = (index: number, value: string) => {
    const updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };
  const handleSpecialRequestChange = (event: any) => {
    specialRequest.current = event.target.value;
  };

  const fetchCart = async (userID: string) => {
    let existingCart: any = {};
    if (userID.length > 0) {
      const response = await getCart(userID);
      const onlineCart = response.length === 0 ? {} : response[0];
      const localCart = getLocalCart();
      updateOfflineCart([], 0);
      if (onlineCart.orderedItems?.length > 0) {
        existingCart = mergeLocalAndOnlineCart(localCart, onlineCart);
        if (onlineCart.totalPrice !== existingCart.totalPrice) {
          // Cart was changed after the merge, update the online cart
          await updateOnlineCart(existingCart.orderedItems, existingCart.totalPrice, userID);
        }
      } else {
        existingCart = localCart;
      }
    } else {
      existingCart = getLocalCart();
    }
    const totalQuantity = getCartItemsQuantity(existingCart);
    setDefaultNotes(existingCart?.orderedItems || []);
    setCartData(existingCart);
    dispatch(setCart(existingCart?.orderedItems || []));
    dispatch(setCartQuantity(totalQuantity));
    dispatch(setCartTotal(existingCart?.totalPrice || 0));
  };

  useEffect(() => {
    let userID = user?.id || "";
    fetchCart(userID);
  }, [user]);

  return (
    <Grid container sx={{ padding: "1rem" }}>
      <Grid item sm={12} lg={12}>
        <Link to="/">
          <Button
            sx={{
              backgroundColor: "rgba(169, 169, 169, 0.55)",
              color: "rgba(64, 64, 64, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: " 2rem",
              height: "2rem",
              borderRadius: "0.5rem",
              marginBottom: "1rem",

            }}
          >
            <ArrowBackIosNewIcon
              sx={{ color: "#ff189c", fontSize: "1rem" }}
            />
          </Button>
        </Link>
      </Grid>

      {items.length < 1 ? <EmptyCart /> : (<Grid
        container
        sx={{
          color: theme.palette.primary.main,
          padding: "1rem",
          marginBottom: "3rem",
        }}
      > <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={warning}
        autoHideDuration={3000}
        onClose={handleWarningClose}
      >
          <Alert
            onClose={handleWarningClose}
            severity="warning"
            sx={{
              position: "fixed",
              top: "16px",
              right: "16px",
            }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleWarningClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            Sorry, there was a problem while placing your order please try again
            later...
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={success}
          autoHideDuration={3000}
          onClose={handleSuccessClose}
        >
          <Alert
            onClose={handleSuccessClose}
            severity="success"
            sx={{
              position: "fixed",
              top: "16px",
              right: "16px",
            }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSuccessClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            Order placed successfully
          </Alert>
        </Snackbar>

        <Grid container>
          {/* //items */}
          {items.map((item, index) => (
            <Grid
              container
              key={item.id}
              sx={{
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "0.3rem",
                marginY: "0.1rem",
                padding: "0.5rem",
                justifyContent: "space-around",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)", // Box shadow
              }}
            >
              {/* the image */}
              <Grid item sm={3} xs={3} >
                <img
                 src={item.image.substring(1,item.image.length+1)}
                 onError={(e) => {
                   const target = e.target as HTMLImageElement;
                   target.src = item.image.substring(2,item.image.length+1); // Fallback to default image on error loading API image
                 }}
                style={{
                    width: "90%",
                    borderRadius: "1rem",
                    border: "0.1rem solid #ff189c ",
                  }}
                />
              </Grid>
              {/* the info */}
              <Grid item sm={8} xs={9}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      // fontSize: 30,
                      fontSize: {
                        xs: 20,
                        sm: 30,
                      },
                      fontWeight: 700,
                      fontFamily: "Roboto",
                    }}
                  >
                    {capitalization(item.name)}
                  </Typography>
                  {/* Remove button */}
                  <Button
                    disabled={disableBtns}
                    onClick={() => handleRemoveItem(index)}
                    sx={{
                      textTransform: "none",
                      color: "red",
                    }}
                  >
                    Remove
                  </Button>
                </Box>
                <Grid container justifyContent="space-between">
                  <Grid item sm={6} xs={12}>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: 10,
                          sm: 15,
                        },
                        marginBottom: "0.2rem",
                        fontWeight: 700,
                        fontFamily: "Roboto",
                        whiteSpace: 'pre-line',  // This CSS property preserves newlines
                      }}
                    >
                      {capitalization(item.priceName)}
                    </Typography>
                    {item.choiceNames.length > 0 && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-end"
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: 10,
                              sm: 15,
                            },
                            marginBottom: "0.5rem",
                            fontWeight: 700,
                            fontFamily: "Roboto",
                            whiteSpace: 'normal',
                          }}
                        >
                          {item.choiceNames.map((choiceName: string, choiceIndex: number) => (
                            <span key={choiceIndex}>
                              {capitalization(choiceName)}
                              {choiceIndex < item.choiceNames.length - 1 ? ', ' : ''}
                            </span>
                          ))}
                        </Typography>
                      </Box>
                    )}


                  
                  </Grid>
                  <Grid item container sm={12} xs={12}>
                    <Grid item xs={4} container alignContent={"center"}>
                  <Typography
                      sx={{
                        // fontSize: 24,
                        fontSize: {
                          xs: 16,
                          sm: 24,
                        },
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                       {item.price} {currency.SAR}
                    </Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                                          >
                      {/* Increment and Decrement buttons with Quantity display */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {/* Decrement button */}
                        <Button

                         
                          sx={{
                            minWidth: "40px",
                            color:"white",
                            height: "2rem",
                            borderRadius: "14px",
                            marginLeft: "0.3rem",
                            backgroundColor: "#ff189c",
                            "&:hover": {
                              boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                              backgroundColor: "#ff189c",
                            },
                          }}
                          onClick={() => handleDecrement(index)}
                          disabled={disableBtns}
                        >
                          -
                        </Button>

                        {/* Quantity display */}
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            padding: "0.3rem 1rem",
                          }}
                        >
                           {items[index].quantity}
                        </Typography>

                        {/* Increment button */}
                        <Button

                        
                          sx={{
                            height: "2rem",
                            color:"white",
                            backgroundColor: "#ff189c",
                            minWidth: "40px",
                            borderRadius: "14px",
                            padding: "0rem",
                            "&:hover": {
                              boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                              backgroundColor: "#ff189c",
                            },
                          }}
                          onClick={() => handleIncrement(index)}
                          disabled={disableBtns}
                        >
                          +
                        </Button>
                      </Box>
                    </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  id="outlined-basic"
                  placeholder="add your notes..."
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#D3D3D3",
                      },
                      "&:hover fieldset": {
                        borderColor: "#D3D3D3",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#D3D3D3",
                      },
                      "& input": {
                        color: theme.palette.primary.main,
                      },
                      "& input::placeholder": {
                        color: theme.palette.primary.main,
                      },
                    },
                    borderRadius: "30px",
                    borderColor: "#D3D3D3",
                    width: "100%",
                    height: "3rem",
                    margin: "1rem auto",
                  }}
                  value={notes[index]}
                  onChange={(e) => handleNoteChange(index, e.target.value)}
                />
              </Grid>
            </Grid>
          ))}
      </Grid>
        {/* //note */}
      <Grid container>
        <Grid
          item
          sm={12}
          xs={12}
          sx={{ marginBottom: "2rem" }}
        >
          <TextField
            id="outlined-basic"
            placeholder="Any special request?"
            multiline
            rows={4}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#D3D3D3",
                },
                "&:hover fieldset": {
                  borderColor: "#D3D3D3",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#D3D3D3",
                },
                "& input": {
                  color: "#D3D3D3",
                },
                "& input::placeholder": {
                  color: theme.palette.primary.main,
                  display: "flex",
                  alignItems: "center", // Aligns placeholder text and icon vertically
                },
                display: "flex",
                alignItems: "flex-start",
                color: theme.palette.primary.main,
              },
              borderRadius: "20px",
              width: "100%",
              height: "3rem",
              margin: "0.5rem auto",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ color: theme.palette.primary.main }}
                >
                  <EditNoteIcon
                    sx={{
                      color: theme.palette.primary.main,
                      marginTop: "1.25rem",
                    }}
                  />
                </InputAdornment>
              ),
            }}
            onChange={handleSpecialRequestChange}
          />
        </Grid>
      </Grid>

      {/* addMore */}
      <Grid
        container
        sm={12}
        xs={12}
        sx={{
          marginTop: "2rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "#00F0FF",
            cursor: "pointer",
          }}
        >
          <Button

            sx={{
              height: "2rem",
              minWidth: "40px",
              padding: "0rem",
              marginRight: "1rem",
              color: "white",
              backgroundColor: "#ff189c",
              "&:hover": {
                boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                backgroundColor: "#ff189c",
              },
            }}
          >
            +
          </Button>
        </Link>
        <Typography>
          {items.length > 0 ? "Add more items" : "Add items"}
        </Typography>
      </Grid>
      {/* //order */}
      <Grid
        container

        sx={{
          padding: "1.5rem",
          backgroundColor: themeMode === "light" ? "#393744" : "#D3D3D3",
          borderRadius: "0.3rem",
        }}
      >
        <Grid item sm={12} xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography
            // sx={{ color: "white" }}

            >
              Sub-Total
            </Typography>
            <Typography
            // sx={{ color: "white" }}

            >
              {totalPrice} {currency.SAR}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography
            // sx={{ color: "white" }}

            >
              Delivery Charge
            </Typography>
            <Typography
            // sx={{ color: "white" }}

            >
              0 {currency.SAR}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={12} xs={12} sx={{ marginTop: "1rem" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography
            // sx={{ color: "white" }}

            >
              Total
            </Typography>
            <Typography
            // sx={{ color: "white" }}

            >
              {totalPrice} {currency.SAR}
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Button


            onClick={handleOrderPlacement}
            disabled={disableBtns}
            sx={{
              font: "0.4rem",
              marginTop: "0.5rem",
              padding: "1rem",
              width: "100%",
              // color: "#FFFFFF",
              backgroundColor: "#ff189c",
              color:"white",
              "&:hover": {
                boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                backgroundColor: "#ff189c",
              },
            }}
          >
            Place My Order
          </Button>
        </Grid>
      </Grid></Grid>)
}


       
        
     
    </Grid >
  );
}
export default Cart;
