import {
  Box,
  Grid,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin, setMode } from "../../state/index";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import "./sidebar.css";
import { signOut } from "aws-amplify/auth";
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import LuggageIcon from '@mui/icons-material/Luggage';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { updateOfflineCart } from "../../services/cartUtils";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Diversity3Icon from '@mui/icons-material/Diversity3';
function NavCatg() {
  const theme = useTheme();
  const navigate = useNavigate();
  const user: any = useSelector((state: any) => state.user);

  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const sidebarRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const handleLogOut = async () => {
    if (!user) {
      return;
    }
    dispatch(setLogin({ user: null }));
    updateOfflineCart([] , 0);
    if (user.group === "Cognito") {
      navigate("/login");
    }
    await signOut();
  };

  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    }

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
      ref={sidebarRef}
      sx={{
        overflow: "auto",
        backgroundColor: theme.palette.background.default,
        padding: "0.1rem",
        width: "100%",
        position: "sticky",
        top: 0,
        zIndex: 199,
      }}
    >
      <Box>
        <IconButton
          onClick={toggleMenu}
          sx={{
            color: theme.palette.primary.main,
            boxShadow: "none",
            transition: "box-shadow 0.3s",
            fontSize: "25px",
          }}
        >
           <MenuIcon />
        </IconButton>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            className={`side-menu ${isMenuOpen ? "open" : ""}`}
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                onClick={closeMenu}
                className="close-btn"
                sx={{
                  color: theme.palette.primary.main,
                  boxShadow: "none",
                  transition: "box-shadow 0.3s",
                  fontSize: "25px",
                  "&:hover": {
                    boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                    backgroundColor: "#ff6e0c",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            {/* /profile */}
            <Box display="flex" flexDirection="column">
              <Link
                to="/profile"
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PersonOutlineIcon
                    style={{
                      marginRight: "0.5rem",
                      width: "24px",
                      height: "24px",
                      verticalAlign: "middle",
                      color: "#ff6e0c",
                    }}
                  />
                  Profile
                </Typography>
              </Link>
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            />

            {/* /address */}
            <Box>
              <Link
                to="/address"
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LocationOnIcon
                    sx={{
                      color: "#ff189c",
                      marginRight: "0.5rem",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  Address
                </Typography>
              </Link>
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            />

            {/* /orders */}
            <Box>
              <Link
                to="/orders"
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LuggageIcon
                    style={{
                      marginRight: "0.5rem",
                      width: "24px",
                      height: "24px",
                      color: "#ff6e0c"
                    }}
                  />
                  Orders
                </Typography>
              </Link>
            </Box>

            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            />
            {/* /Menu */}
            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MenuOpenIcon
                    style={{
                      color: "#ff189c",
                      marginRight: "0.5rem",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  Menu
                </Typography>
              </Link>
            </Box>

            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            />
            <Box>
              <Link
                to="/about"
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Diversity3Icon
                    style={{
                      marginRight: "0.5rem",
                      width: "24px",
                      height: "24px",
                      color: "#ff6e0c",
                    }}
                  />
                  About Us
                </Typography>
              </Link>
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            />

            {/* /contact */}
            <Box>
              <Link
                to="/contact"
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >

                  <SettingsPhoneIcon style={{
                    marginRight: "0.5rem",
                    width: "24px",
                    height: "24px",
                    color: "#ff189c",
                  }} />
                  Contact Us
                </Typography>
              </Link>
            </Box>

            {/* /about */}

            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            />

            {user && (
              <>
                {" "}
                <Box
                  onClick={() => {
                    handleLogOut();
                  }}
                >
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      fontSize: 20,
                      padding: "1rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LogoutIcon
                      sx={{
                        color: "#ff6e0c",
                        marginRight: "0.5rem",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    Logout
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    width: "100%",
                    borderWidth: "0.1rem",
                    backgroundColor: "#9B9B9B",
                  }}
                />
              </>
            )}
          </Box>
         
      </Box>
      <Grid item>
        <Link
          to="/"
          style={{ textDecoration: "none", color: "white", cursor: "pointer" }}
        >
         
            <img
              src="assets/DUNKIN/MenuItems/logo.ico"
              style={{ width: "4.5rem", height: "4rem" }}
            />
         
        </Link>
      </Grid>
      <Grid item>
        <Link
          to="/notifications"
          style={{ textDecoration: "none", color: "white", cursor: "pointer" }}
        >
          <IconButton
            sx={{
              color: theme.palette.primary.main,
              boxShadow: "none",
              transition: "box-shadow 0.3s",
              fontSize: "25px",
              "&:hover": {
                boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                backgroundColor: "#ff6e0c",
              },
            }}
          >
            <NotificationsIcon />
          </IconButton>
        </Link>
      </Grid>
    </Grid>
  );
}

export default NavCatg;
