import {
  Box,
  Grid,
  useTheme,
  Divider,
  Typography,
  Button,
  Checkbox,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useEffect, useState, useRef } from "react";
import { capitalization } from "../../constants/helperFunction";
import { useDispatch, useSelector } from "react-redux";
import { setCart, setCartQuantity, setCartTotal } from "../../state/index";
import { useNavigate } from "react-router-dom";
import * as cartServices from "../../services/cartServices";
import * as cartUtils from "../../services/cartUtils";
import { concept, currency } from "../../constants/Enums";
import { cartItem } from "../../constants/cartItem";
//mytext theme.palette.primary.dark
interface Price {
  id: string;
  name: string;
  price: number;
}

function MenuItems() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const selectedItem = useSelector((state: any) => state.selectedItem || {});

  const categories = useSelector((state: any) => state.categoriesState || {});
  const user = useSelector((state: any) => state.user);
  const defaultPrice = selectedItem?.prices?.[0]?.price || 0;
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [addToCartDisabled, setAddToCartDisabled] = useState<boolean>(false);
  const [validationWarning, setValidationWarning] = useState<boolean>(false);
  const [selectedSize, setSelectedSize] = useState<any | null>({
    name: selectedItem?.prices[0]?.name || null,
    priceId: selectedItem?.prices[0]?.id || null,
    price: selectedItem?.prices[0]?.price || null,
  });
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const notes = useRef("");
  const itemNames = ['Cappucino', 'Latte', 'Mocha', 'Bagel Cinnamon Double Cheese', 'Bagel Cream Cheese Plain', 'Bagel Cream Cheese Oats']
const choicesNames =['milk','caremel','moca','chocolate','Cappucino', 'Latte', 'Mocha',]
  const handleCheckboxChange = (choiceId: string, choicePrice: number) => {
    const newCheckedItems = { ...checkedItems };
    newCheckedItems[choiceId] = !newCheckedItems[choiceId];
    setCheckedItems(newCheckedItems);
    let newTotalPrice = defaultPrice * quantity;
    selectedItem.choiceGroups.forEach((choiceGroup: any) => {
      if (choiceGroup.choices) {
        choiceGroup.choices.forEach((choice: any) => {
          if (newCheckedItems[choice.id]) {
            newTotalPrice += choice.price;
          }
        });
      }
    });
    setTotalPrice(newTotalPrice);
  };
  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
    // setTotalPrice(defaultPrice * (quantity + 1));
  };

  const handleDecrement = () => {
    if (quantity === 1) return;
    setQuantity((prevQuantity) => {
      if (prevQuantity > 0) {
        setTotalPrice(defaultPrice * (prevQuantity - 1));
        return prevQuantity - 1;
      }
      return prevQuantity;
    });
  };

  const updateCartState = (updatedCart: any) => {
    const totalQuantity = cartUtils.getCartItemsQuantity(updatedCart);
    dispatch(setCart(updatedCart.orderedItems));
    dispatch(setCartQuantity(totalQuantity));
    dispatch(setCartTotal(updatedCart.totalPrice));
  };

  const countChoices = (selectedChoices: any, availableChoices: any): number => {
    if (selectedChoices.length < 1) return 0;
    let counter = 0;
    for (const availableChoice of availableChoices) {
      const idx = selectedChoices.findIndex((choiceID: any) => choiceID === availableChoice.id);
      if (idx >= 0) counter++;
    }
    return counter;
  }


  const isSelectionValidated = (choiceIDs: any, choiceGroups: any): boolean => {
    for (const choiceGroup of choiceGroups) {
      if (choiceGroup.required) {
        const { maxNumberOfChoices, minNumberOfChoices } = choiceGroup;
        const choicesCount = countChoices(choiceIDs, choiceGroup.choices);
        if (choicesCount < minNumberOfChoices || choicesCount > maxNumberOfChoices) {
          setValidationWarning(true);
          setAddToCartDisabled(false);
          return false;
        }
      }
    }
    return true;
  }


  const handleCartUpdateOffline = (conceptID: any, cartItem: any, totalPrice: number) => {
    const existingCart = cartUtils.getLocalCart();

    if (!existingCart) {
      // User doesn't have a cart, so create a new one
      const newCart = {
        conceptID,
        orderedItems: [cartItem],
        totalPrice,
      };

      cartUtils.setLocalCart(newCart);
      updateCartState(newCart);
    } else {
      // User already has a cart, so update it by adding the new cart item
      const updatedCart = {
        ...existingCart,
        orderedItems: [...existingCart.orderedItems, cartItem],
        totalPrice: existingCart.totalPrice + totalPrice,
      };

      cartUtils.setLocalCart(updatedCart);
      updateCartState(updatedCart);
    }
  };

  const handleCartUpdateOnline = async (conceptID: any, userID: string, cartItem: any, totalPrice: number) => {
    const existingCart = await cartServices.getCart(userID);
    if (existingCart.length === 0) {
      // user doesn't has a cart so create a new cart
      const orderedItems = [cartItem];
      const newCart = await cartServices.createCart(
        conceptID,
        userID,
        orderedItems,
        totalPrice
      );
      updateCartState(newCart);
    } else {
      // user already has a cart so update it by adding the new cart item
      const params = { currentCart: existingCart[0], cartItem };
      const updatedCartObj = cartUtils.addToCart(params);
      const updatedCart = await cartServices.updateCart(
        updatedCartObj.id,
        conceptID,
        updatedCartObj._version,
        updatedCartObj.orderedItems,
        updatedCartObj.totalPrice,
        userID
      );
      updateCartState(updatedCart);
    }
  }



  const handleAddToCart = async () => {
    setAddToCartDisabled(true);

    const conceptID = selectedItem.conceptID;
    const choiceIDs: any = [];
    const choiceNames: any = [];
    const choiceSymphonyID: any = [];
    const extraChoiceIDs: any = [];
    const extraChoiceSymphonyID: any = [];
    let preparationAreaID = "";

    if (quantity < 1) return;

    for (const choiceGroup of selectedItem.choiceGroups) {
      choiceGroup.choices.forEach((choice: any) => {
        if (checkedItems[choice.id]) {
          choiceIDs.push(choice.id);
          choiceNames.push(choice.name);
          choiceSymphonyID.push(choice.symphonyID);
        }
      });
    }

    if (!isSelectionValidated(choiceIDs, selectedItem.choiceGroups)) return;

    for (const DunkinCategory of categories.listing) {
      if (DunkinCategory.id === selectedItem.categoryID) {
        preparationAreaID = DunkinCategory.preparationAreaID;
      }
    }

    const cartItem = {
      menuItemID: selectedItem.id,
      symphonyID: selectedItem.symphonyID,
      preparationAreaID,
      name: selectedItem.name,
      quantity,
      choiceIDs,
      choiceNames,
      choiceSymphonyID,
      extraChoiceIDs,
      extraChoiceSymphonyID,
      price: selectedSize.price,
      priceName: selectedSize.name,
      notes: notes.current,
      image: selectedItem.image,
    };


    // IF USER IS LOGGED IN WORK WITH THE ONLINE DB
    if (user) {
      await handleCartUpdateOnline(conceptID, user.id, cartItem, totalPrice);
      // IF USER IS LOGGED OUT WORK WITH LOCALSTORAGE
    } else {
      handleCartUpdateOffline(conceptID, cartItem, totalPrice);
    }

    navigate("/");
  };

  const handleValidationWarningClose = () => {
    setValidationWarning(false);
    setAddToCartDisabled(false);
  }

  const handleSizeSelection = (
    size: string,
    price: number,
    priceId: string
  ) => {
    if (selectedSize.name !== size) {
      setSelectedSize({ name: size, priceId, price });
      setTotalPrice(price * quantity);
    }
  };

  const handleNotesChange = (event: any) => {
    notes.current = event.target.value;
  };

  useEffect(() => {
    if (!selectedItem) {
      navigate("/");
    }
  }, [selectedItem]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const selectedSizePrice = selectedSize.price;
    if (selectedSizePrice) {
      const initialTotalPrice = selectedSizePrice * quantity;
      setTotalPrice(initialTotalPrice);
    }
  }, [selectedSize.price, quantity]);

  if (!selectedItem) { return (<></>) }

  return (
    <>
      <Grid sx={{ padding: "1rem" }}>
        <Grid container>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={validationWarning}
            autoHideDuration={3000}
            onClose={handleValidationWarningClose}
          >
            <Alert
              onClose={handleValidationWarningClose}
              severity="warning"
              sx={{
                position: "fixed",
                top: "16px",
                right: "16px",
              }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleValidationWarningClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              Please select from the required choices
            </Alert>
          </Snackbar>

          <Box display="flex" flexDirection="column">
            <Typography
              style={{
                fontSize: 30,
                color: theme.palette.primary.main,
                fontWeight: 700,
              }}
            >
              {capitalization(selectedItem.name)}
            </Typography>
            <Typography
              sx={{
                overflow: "hidden",
                marginBottom: "0.5rem",
                color: "#9B9B9B",
              }}
            >
              {capitalization(selectedItem.description)}
            </Typography>
          </Box>
          <Grid
            container
            sx={{
              display: "flex",
              flex: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 30,
                  fontWeight: 700,

                  color: "#ff6e0c",
                }}
              >
                {`${selectedItem?.prices[0]?.price} ${currency.SAR}` ||
                  "Price Not Available"}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ display: "flex", justifyContent: "flex-end" }}
              sm={6}
            >
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  sx={{
                    font: "0.3rem",
                    width: "95%",
                    minWidth: "41px",
                    height: "2rem",
                    color: "#FFFFFF",
                    backgroundColor: "#ff6e0c",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s",
                    borderRadius: "1rem",
                    padding: "0rem",
                    "&:hover": {
                      boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                      backgroundColor: "#ff6e0c",
                    },
                  }}
                  onClick={handleDecrement}
                >
                  -
                </Button>
                <Button
                  sx={{
                    width: "1rem",
                    height: "2rem",
                    padding: "0rem",
                    color: theme.palette.primary.main,
                  }}
                >
                  {quantity}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    font: "0.3rem",
                    width: "95%",
                    minWidth: "41px",
                    height: "2rem",
                    color: "#FFFFFF",
                    backgroundColor: "#ff6e0c",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s",
                    borderRadius: "1rem",
                    padding: "0rem",
                    "&:hover": {
                      boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                      backgroundColor: "#ff6e0c",
                    },
                  }}
                  onClick={handleIncrement}
                >
                  +
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs={3}>
              <Typography
                sx={{
                  fontSize: 24,
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                }}
              >
                size :
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={9}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {selectedItem.prices.map((price: Price, index: any) => (
                <Box
                  key={price.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    variant={
                      selectedSize.priceId === price.id
                        ? "contained"
                        : "outlined"
                    }
                    sx={{
                      margin: "0.2rem",
                      color:
                        selectedSize.priceId === price.id ? "white" : "#ff189c",
                      borderColor:
                        selectedSize.priceId === price.id
                          ? "#ff189c"
                          : "inherit",
                      backgroundColor:
                        selectedSize.priceId === price.id
                          ? "#ff189c"
                          : "inherit",
                      "&:hover": {
                        backgroundColor:
                          selectedSize.priceId === price.id
                            ? "#ff189c"
                            : "rgba(51,189,232,0.1)",
                        borderColor: "#ff189c",
                      },
                      padding: "0.1rem 0.5rem",
                      fontSize: "1rem",
                    }}
                    onClick={() =>
                      handleSizeSelection(price.name, price.price, price.id)
                    }
                  >
                    {price.name}
                  </Button>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderColor: "#393744",
          borderWidth: "0.2rem",
          marginY: "0.5rem",
        }}
      />
      {/* ///extrasss */}

      <Grid sx={{ padding: "1rem" }}>
        <Grid container justifyContent="space-between">
          {selectedItem.choiceGroups &&
            selectedItem.choiceGroups.map((choiceGroup: any, index: any) =>
              choiceGroup.choices.length > 0 ? (
                <Grid key={choiceGroup.id} container item sm={12}>
                  <Typography
                    key={choiceGroup.id}
                    style={{
                      fontSize: 24,
                      fontWeight: 700,
                      color: theme.palette.primary.main,
                    }}
                  >
                    {capitalization(selectedItem.name)}
                  </Typography>
                  <Grid container justifyContent="space-between">
                    <Grid item sm={6}>
                      {choiceGroup.maxNumberOfChoices > 0 &&
                        choiceGroup.choices.length > 0 && (
                          <Typography
                            sx={{
                              overflow: "hidden",
                              fontSize: 12,
                              marginBottom: "0.5rem",
                              color: "#9B9B9B",
                            }}
                          >
                            {`Max ${choiceGroup.maxNumberOfChoices}`}
                          </Typography>
                        )}
                    </Grid>

                    <Grid item sm={2}>
                      <Button
                        key={choiceGroup.id}
                        variant="contained"
                        sx={{
                          fontSize: "0.6rem",
                          borderRadius: 50,
                          color: "white",
                          backgroundColor: "#393744",
                          pointerEvents: "none", // Disable pointer events to prevent interaction
                        }}
                      >
                        {`${choiceGroup.minNumberOfChoices === 1
                            ? "Required"
                            : "Optional"
                          }`}
                      </Button>
                    </Grid>
                  </Grid>
                  {choiceGroup.choices &&
                    choiceGroup.choices.map((choice: any) => (
                      <Grid
                        container
                        key={choice.id}
                        justifyContent="space-between"
                      >
                        <Grid item sm={3}>
                          <Typography
                            sx={{
                              font: "1rem",
                              fontWeight: 500,
                              marginTop: "0.5rem",
                              color: theme.palette.primary.main,
                            }}
                          >
                            {choice.name}
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Box display="flex" justifyContent="center">
                            <Checkbox
                              sx={{
                                color: theme.palette.primary.main, // Border color in default state
                                "&.Mui-checked": {
                                  color: "#ff189c", // Border color when checked
                                },
                                "&:hover": {
                                  color: "#ff189c", // Border color on hover (optional)
                                },
                              }}
                              onChange={() =>
                                handleCheckboxChange(choice.id, choice.price)
                              }
                              checked={checkedItems[choice.id] || false}
                            />
                           {choice.price!==0 &&( <Typography
                              sx={{ color: "#9B9B9B", marginTop: "0.5rem" }}
                            >
                              + {choice.price} {currency.SAR}
                            </Typography>)}
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              ) : (
                ""
              )
            )}

          <Grid container sx={{ marginTop: "1rem", marginBottom: "3rem" }}>
            <Grid
              item
              sm={12}
              xs={12}
              sx={{ marginBottom: "1rem" }}
            >
              <TextField
                id="outlined-basic"
                placeholder="Any special request?"
                multiline
                rows={4}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.palette.primary.main,
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.primary.main,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.primary.main,
                    },
                    "& input": {
                      color: theme.palette.primary.main,
                    },
                    "& input::placeholder": {
                      color: theme.palette.primary.main,
                      display: "flex",
                      alignItems: "center", // Aligns placeholder text and icon vertically
                    },
                    display: "flex",
                    alignItems: "flex-start",
                    color: theme.palette.primary.main,
                  },
                  borderRadius: "20px",
                  width: "100%",
                  height: "3rem",
                  margin: "1rem auto",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      <EditNoteIcon
                        sx={{
                          color: theme.palette.primary.main,
                          marginTop: "1.25rem",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                // value={specialRequests[0]}
                onChange={handleNotesChange}
              />
            </Grid>
          </Grid>

          {addToCartDisabled ? (
            <Grid
              container
              item
              sm={12}
              sx={{ marginTop: "1rem", marginBottom: "3rem" }}
              justifyContent="center"
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              container
              item
              sm={12}
              sx={{ marginTop: "1rem", marginBottom: "3rem" }}
            >
              <Button
                variant="contained"
                sx={{
                  font: "0.3rem",
                  width: "100%",
                  color: "#FFFFFF",
                  backgroundColor: "#ff6e0c",
                  boxShadow: "none",
                  transition: "box-shadow 0.3s",
                  borderRadius: "1rem",
                  padding: "1rem",
                  "&:hover": {
                    boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                    backgroundColor: "#ff6e0c",
                  },
                }}
                onClick={handleAddToCart}
                disabled={addToCartDisabled}
              >
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box> Add to Cart </Box>
                  <Box>
                    {totalPrice} {currency.SAR}
                  </Box>
                </Box>
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default MenuItems;
