import { getCart, updateCart } from "./cartServices";

function addToCart(params: any) {
  try {
    const { currentCart, cartItem, specialRequest } = params;

    const existingItemIndex = currentCart.orderedItems.findIndex(
      (item: any) =>
        item.menuItemID +
          item.price +
          item.priceName +
          item.choiceIDs.sort() +
          item.notes ===
        cartItem.menuItemID +
          cartItem.price +
          cartItem.priceName +
          cartItem.choiceIDs.sort() +
          cartItem.notes
    );

    let newOrderdItems = [...currentCart.orderedItems];
    if (existingItemIndex !== -1) {
      // Update existing item with new values
      cartItem.quantity += newOrderdItems[existingItemIndex].quantity;
      newOrderdItems[existingItemIndex] = cartItem;
    } else {
      // Add new item to array
      newOrderdItems.push(cartItem);
    }

    for (let i = 0; i < newOrderdItems.length; i++) {
      delete newOrderdItems[i].__typename;
    }

    const updateInput: any = {
      id: currentCart.id,
      orderedItems: newOrderdItems,
      _version: currentCart._version,
    };
    let totalPrice = 0;
    for (let item of updateInput.orderedItems) {
      totalPrice = totalPrice + (item?.price ?? 0) * (item?.quantity ?? 0);
    }
    updateInput.totalPrice = totalPrice;
    if (specialRequest) {
      updateInput.specialRequest = specialRequest;
    }
    return updateInput;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

function mergeLocalAndOnlineCart (localCart:any, onlineCart:any) {
  try {
    const mergedCart = { ...onlineCart };
    if(!localCart) return mergedCart;

    for (const localCartItem of localCart.orderedItems) {
      const existingItemIndex = mergedCart.orderedItems.findIndex(
        (onlineCartItem: any) =>
          onlineCartItem.menuItemID +
            onlineCartItem.price +
            onlineCartItem.priceName +
            onlineCartItem.choiceIDs.sort() +
            onlineCartItem.notes ===
          localCartItem.menuItemID +
            localCartItem.price +
            localCartItem.priceName +
            localCartItem.choiceIDs.sort() +
            localCartItem.notes
      );

      if (existingItemIndex !== -1) {
        const onlineCartItem = mergedCart.orderedItems[existingItemIndex];
        // Update quantity if the item is already in onlineCart
        onlineCartItem.quantity += localCartItem.quantity;
      } else {
        // Add new item to onlineCart if not found
        mergedCart.orderedItems.push(localCartItem);
      }
    }

    // Recalculate total price
    let totalPrice = 0;
    for (const item of mergedCart.orderedItems) {
      totalPrice += (item?.price ?? 0) * (item?.quantity ?? 0);
    }

    mergedCart.totalPrice = totalPrice;

    return mergedCart;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

function getCartItemsQuantity(cart: any) {
  const cartItems = cart?.orderedItems || [];
  if (cartItems.length === 0) return 0;
  let quantity = 0;
  for (const cartItem of cart.orderedItems) {
    quantity += cartItem.quantity;
  }
  return quantity;
}

function getCartTotalPrice(cart: any) {
  if (cart.orderedItems.length === 0) return 0;
  let totalPrice = 0;
  for (const item of cart.orderedItems) {
    totalPrice += item.price * item.quantity;
  }
  return totalPrice;
}

async function updateOnlineCart(
  newOrderedItems: any,
  newTotalPrice: number,
  userID: string
) {
  try {
    const response = await getCart(userID);
    const existingCart = response[0];
    existingCart.totalPrice = newTotalPrice;
    existingCart.orderedItems = removeTypeNameFromItems(newOrderedItems);
    await updateCart(
      existingCart.id,
      existingCart.conceptID,
      existingCart._version,
      existingCart.orderedItems,
      existingCart.totalPrice,
      userID
    );
  } catch (err) {
    console.log(`error updating online cart: ${err}`);
  }
}

function removeTypeNameFromItems(existingItems: any) {
  return existingItems.map((cartItem: any) => {
    return {
      menuItemID: cartItem.menuItemID,
      symphonyID: cartItem.symphonyID,
      preparationAreaID: cartItem.preparationAreaID,
      name: cartItem.name,
      quantity: cartItem.quantity,
      choiceIDs: cartItem.choiceIDs,
      choiceNames: cartItem.choiceNames,
      choiceSymphonyID: cartItem.choiceSymphonyID,
      extraChoiceIDs: cartItem.extraChoiceIDs,
      extraChoiceSymphonyID: cartItem.extraChoiceSymphonyID,
      price: cartItem.price,
      priceName: cartItem.priceName,
      notes: cartItem.notes,
      image: cartItem.image,
    };
  });
}

function decrementOrRemoveItem(
  cartItems: any,
  updatedItem: any,
  index: number
) {
  if (updatedItem.quantity > 0) return cartItems;
  cartItems.splice(index, 1);
  return cartItems;
}

function countItemVersions(itemID: string, items: any) {
  let counter = 0;
  for (let i = 0; i < items.length; i++) {
    if (items[i].menuItemID === itemID) counter++;
  }
  return counter;
}

function getLocalCart(): any | null {
  const cartJson = localStorage.getItem('userCart');
  return cartJson ? JSON.parse(cartJson) : null;
}

function setLocalCart(cart: any): void {
  localStorage.setItem('userCart', JSON.stringify(cart));
}

 function updateOfflineCart (newOrderedItems: any, newTotalPrice: number) {
    const localCart = getLocalCart();
    if(!localCart) return;
    localCart.orderedItems = newOrderedItems;
    localCart.totalPrice = newTotalPrice;
    setLocalCart(localCart);
  }


export {
  addToCart,
  getCartItemsQuantity,
  getCartTotalPrice,
  updateOnlineCart,
  removeTypeNameFromItems,
  decrementOrRemoveItem,
  countItemVersions,
  getLocalCart,
  setLocalCart,
  updateOfflineCart,
  mergeLocalAndOnlineCart
};
