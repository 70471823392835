import { EndPoints ,account} from "../constants/Enums";
import axios from "axios";

async function createUser(
    user:any,
    group:any
) {
  try {
    const accountID=account.accountID
    
    const operationId = 16;
    const userAttributes={
      email:user.email,
      name:user.name?user.name:"user",
      phone_number:user.phone_number?user.phone_number:"00",
      group:group,
      createdAt: new Date(),
      createdByID:user.sub,
      createdByName: user.email,
    }
    
    const requestBody = {
      operationId,
      accountID:accountID,
      userID:user.sub,
      userAttributes:userAttributes
    };
    console.log(requestBody)
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    const response = await axios.post(EndPoints.getCategoryData, requestBody);
    return response.data;

  } catch (error) {
    console.error("Error creating user :", error);
    throw error;
  }
}

export default createUser;
