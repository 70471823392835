import { IconButton, Grid, Box, Typography, useTheme } from "@mui/material";
import UpdateSharpIcon from "@mui/icons-material/UpdateSharp";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import KitchenIcon from "@mui/icons-material/Kitchen";
import RoomServiceOutlinedIcon from "@mui/icons-material/RoomServiceOutlined";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { OrderStatus } from "../../constants/Enums";
import { onUpdateOnlineOrder } from "../../graphql/subscriptions";
import { generateClient } from "aws-amplify/api";
import { setCurrentOrder } from "../../state/index";
import { useSelector } from "react-redux";
import { capitalization } from "../../constants/helperFunction";

const API = generateClient();

function OrderTrack() {
  const theme = useTheme();
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();
  const order = useSelector((state: any) => state.currentOrder);
  const [pending, setPending] = useState(true);
  const [placed, setPlaced] = useState(false);
  const [outForDelivery, setOutForDelivery] = useState(false);
  const [delivered, setDelivered] = useState(false);

  function updateTrack(order: any) {
    switch (order.status) {
      case OrderStatus.pending: {
        setPending(true);
        setPlaced(false);
        setDelivered(false);
        setOutForDelivery(false);
        break;
      }
      case OrderStatus.confirmed: {
        setPending(true);
        setPlaced(true);
        setDelivered(false);
        setOutForDelivery(false);
        break;
      }
      case OrderStatus.outForDelivery: {
        setPending(true);
        setPlaced(true);
        setOutForDelivery(true);
        setDelivered(false);
        break;
      }
      case OrderStatus.delivered: {
        setPending(true);
        setPlaced(true);
        setDelivered(true);
        setOutForDelivery(true);
        break;
      }
      default: {
        break;
      }
    }
  }

  const handleRating = (value: number) => {
    setRating(value === rating ? 0 : value);
  };
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <IconButton color="primary" key={i} onClick={() => handleRating(i)}>
          {rating >= i ? (
            <StarIcon sx={{ color: "yellow" }} />
          ) : (
            <StarBorderIcon />
          )}
        </IconButton>
      );
    }
    return stars;
  };

  useEffect(() => {
    updateTrack(order);

    const subscription = API.graphql({ query: onUpdateOnlineOrder });

    if ("subscribe" in subscription) {
      const orderSubscription = subscription.subscribe({
        next: (event: any) => {
          const updatedOrder = event.data.onUpdateOnlineOrder;

          if (updatedOrder.id === order.id) {
            dispatch(setCurrentOrder(updatedOrder));
            updateTrack(updatedOrder);
          }
        },
        error: (error: any) => {
          console.error("Subscription error:", error);
        },
      });

      return () => {
        if ("unsubscribe" in orderSubscription) {
          orderSubscription.unsubscribe();
        }
      };
    }
  }, []);

  return (
    <Grid sx={{ padding: "1rem" }}>
      <Box>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "600",
          }}
        >
          Track Order
        </Typography>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: "600",
          }}
        >
          {new Date(order.createdAt).toDateString()}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: "400",
            color: "#9D9D9D",
          }}
        >
          {`Order ID: #${capitalization(order.id.substring(0, 8))}`}
        </Typography>
      </Box>

      {pending && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "2rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              Your order is pending
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              Waiting for confirmation
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            {/* <img src="assets/time.png" alt="" /> */}
            <UpdateSharpIcon fontSize="large" style={{ color: "#9D9D9D" }} />
          </Grid>
        </Grid>
      )}

      {placed && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "1rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              Your order is placed
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              Order is being prepared
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            {/* <img src="assets/pot (1).png" alt="" /> */}
            <KitchenIcon fontSize="large" style={{ color: "#9D9D9D" }} />
          </Grid>
        </Grid>
      )}

      {outForDelivery && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "2rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              Your order is on the way
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              Order is being delivered to you
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            {/* <img src="assets/truck.png" alt="" /> */}
            <DeliveryDiningOutlinedIcon
              fontSize="large"
              style={{ color: "#9D9D9D" }}
            />
          </Grid>
        </Grid>
      )}

      {delivered && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "1rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              Your order is delivered
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              Order received, enjoy your food!
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            {/* <img src="assets/pot (1).png" a/lt="" /> */}
            <RoomServiceOutlinedIcon
              fontSize="large"
              style={{ color: "#9D9D9D" }}
            />
          </Grid>
        </Grid>
      )}

      {delivered && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "1rem",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
          }}
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "400",
            }}
          >
            Don’t forget to rate your experience
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "400",
              color: "#9D9D9D",
            }}
          >
            we would love to hear about it
          </Typography>
          <Box> {renderStars()}</Box>
        </Grid>
      )}
    </Grid>
  );
}

export default OrderTrack;
