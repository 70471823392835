import { Container, Box, Grid } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useState, useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "./shimmer.css"; 

function OrderHistorySkeleton() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Container>
            <SkeletonTheme  baseColor="#c0c0c0" highlightColor="#ff6e0c">
                <Grid container justifyContent="space-between" sx={{ marginTop: "2rem" }}>
                    <Skeleton  className="shimmer" height={15} width={15} />
                </Grid>
                <Box
                    sx={{
                        width: "100%",
                        whiteSpace: "nowrap",
                    }}
                >
                    <Skeleton  className="shimmer"  height={5}  width={20}/>
                </Box>

                <Box
                    sx={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        marginY:"1rem",
                    }}
                >
                    <Skeleton  className="shimmer"  height={5}  width={20}/>
                    <Skeleton  className="shimmer" height={100} />
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        marginY:"1rem",
                    }}
                >
                    <Skeleton   className="shimmer" height={100} />
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        marginY:"1rem",
                    }}
                >
                    <Skeleton  className="shimmer"  height={100} />
                </Box>






            </SkeletonTheme>
        </Container>
    );
}

export default OrderHistorySkeleton;
