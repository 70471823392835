import axios from "axios";
import { EndPoints } from "../constants/Enums";

type RequestData = {
  [key: string]: any; 
};

export async function getUserOrders(userID: string) {
  const operationId = 6;

  try {
    const requestBody = { operationId, userID }; // Include userID in the request body
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    const response = await axios.post(EndPoints.getCategoryData, requestBody);
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching user orders for user ID ${userID}:`,
      error
    );
    throw error;
  }
}
