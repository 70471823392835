import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { Amplify } from "aws-amplify";
// import config from "./amplifyconfiguration.json";

//reduxSetup
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import appSlice from "./state/index";
//redux-store
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Amplify } from "aws-amplify";

import config from "./aws-exports";
import { BrowserRouter } from "react-router-dom";

Amplify.configure(config);

const persistConfig = { key: "root", storage, version: 1 };
const persistedReducer = persistReducer(persistConfig, appSlice);

const store = configureStore({
  reducer: persistedReducer,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <PersistGate loading={null} > */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/* </PersistGate> */}
  </Provider>
  // </React.StrictMode>,
);
