import { EndPoints } from "../constants/Enums";

async function getCart(userID:string) {
    try {
        const operationId = 2;
        
        const requestBody = {
            userID,
            operationId
        };
        const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          };
          const response = await fetch(EndPoints.getCategoryData, options);
          const jsonResponse = await response.json();
          return jsonResponse;
        } catch (error) {
          console.error("Error getting Cart :", error);
          // throw error;
        }
}
      
async function createCart(
  conceptID: string,
  userID: string,
  orderedItems: any,
  totalPrice: number,
) {
  try {
    const operationId = 12
    const requestBody = {
      operationId,
      conceptID,
      userID,
      orderedItems,
      totalPrice,
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    }
    const response = await fetch(EndPoints.getCategoryData, options)
    const jsonResponse = await response.json()
    return jsonResponse
  } catch (error) {
    console.error('Error creating Cart :', error)
    throw error
  }
}

async function updateCart(cartID:string, conceptID:string, version:number, newOrderedItems:any, totalPrice:any, userID:string) {
    try {
        const operationId = 5;
        
        const requestBody = {
          operationId,
          conceptID,
          cartID,
          version,
          userID,
          newOrderedItems,
          totalPrice,
        };
        const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          };
          const response = await fetch(EndPoints.getCategoryData, options);
          const jsonResponse = await response.json();
          return jsonResponse;
        } catch (error) {
          console.error("Error updating Cart :", error);
          throw error;
        }
}
    
export {
    getCart,
    createCart,
    updateCart
}