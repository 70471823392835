import { Grid, useTheme, Typography, Button, IconButton, TextField, InputAdornment } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function AdressPage() {
  const theme = useTheme();
  return (
    <Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '1rem' }}
      >
        <Grid item sm={12} lg={12}>
          <Link to="/">
            <Button
              sx={{
                backgroundColor: 'rgba(169, 169, 169, 0.55)',
                color: 'rgba(64, 64, 64, 0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: ' 2rem',
                height: '2rem',
                borderRadius: '0.5rem',
                marginBottom: '1rem',
              }}
            >
              <ArrowBackIosNewIcon sx={{ color: '#ff189c', fontSize: '1rem' }} />
            </Button>
          </Link>
        </Grid>
        <Grid container item sm={12} lg={12} xs={12} justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: 24,
              fontWeight: '600',
              letterSpacing: 0.96,
            }}
          >
            Address
          </Typography>

          <Link to="/newAddress" style={{ textDecoration: 'none', color: '#ff189c', fontSize: 12 }}>
            Add New
          </Link>
        </Grid>

        {/* //the address */}
        <Grid container item alignItems='center' sm={12} sx={{ marginY: "2rem" }}>
          <Grid sm={2} xs={2}>
            <IconButton
              sx={{ backgroundColor: "#393744", }}
            >
              <img src="assets/location.png" alt="location" />
            </IconButton>
          </Grid>
          <Grid sm={8} xs={8}>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: 14,
              }}
            >
              Street 261, New Maadi
            </Typography>
            <Typography
              sx={{
                color: '#9D9D9D',
                fontSize: 10,
              }}
            >
              Street 261, New Maadi
            </Typography>
          </Grid>
          <Grid sm={1} xs={1} sx={{ marginLeft: "1rem" }}>
            <Link to="/" style={{ textDecoration: 'none', color: '#ff189c', cursor: 'pointer' }}>
              <ArrowForwardIosIcon />
            </Link>
          </Grid>
        </Grid>
        <Grid container item alignItems='center' sm={12} >
          <Grid sm={2} xs={2}>
            <IconButton
              sx={{ backgroundColor: "#393744"}}
            >
              <img src="assets/location.png" alt="location" />
            </IconButton>
          </Grid>
          <Grid sm={8} xs={8}>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: 14,
              }}
            >
              Street 261, New Maadi
            </Typography>
            <Typography
              sx={{
                color: '#9D9D9D',
                fontSize: 10,
              }}
            >
              Street 261, New Maadi
            </Typography>
          </Grid>
          <Grid sm={1} xs={1} sx={{ marginLeft: "1rem" }}>
            <Link to="/" style={{ textDecoration: 'none', color: '#ff189c', cursor: 'pointer' }}>
              <ArrowForwardIosIcon />
            </Link>
          </Grid>
        </Grid>

      </Grid >
   
    </Grid>
  )
}

export default AdressPage
