import { useEffect, useMemo, useState } from "react";
import { Container, Stack, Box, Typography, useTheme, Grid } from "@mui/material";
import Voucherheader from "../../components/voucherheader";
import Searchinput from "../../components/searchinput";
import Categortycontainer from "../../components/categortycontainer";
import Foodcard from "../../components/foodcard";
import { Category, MenuItem } from "../../models/Category";
import { getCategoryData, } from "../../services/getOperation";
import Sidebar from "../../components/sidebar/Sidebar";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import MySkeleton from "../../components/Skeleton/MySkeleton";
//redux
import {
  setCart,
  setCartQuantity,
  setCartTotal,
  setCategories,
  setLogin,
  setMainCategories,
  // setSplashScreen
} from "../../state/index";
import { useDispatch, useSelector } from "react-redux";
import { capitalization } from "../../constants/helperFunction";
import { Link } from "react-router-dom";
import { getCart } from "../../services/cartServices";
import { getCartItemsQuantity, getLocalCart, mergeLocalAndOnlineCart, updateOfflineCart, updateOnlineCart } from "../../services/cartUtils";
import { fetchUserAttributes } from "aws-amplify/auth";
import getUser from "../../services/getUser";
import { create } from "domain";
import createUser from "../../services/createUser";
import { DunkinCategory } from "../../constants/category";
// import SplashScreen from "../../components/splashScreen/SplashScreen";


const CategoryPage = () => {
  const [search, setSearch] = useState("" as string);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  //redux
  const dispatch = useDispatch();
  const categories = useSelector(
    (state: any) => state.categoriesState.listing || []
  );
  const splashScreen = useSelector((state: any) => state.splashScreen);

  const defaultCategoryImage = "/assets/burger.svg";

  const user = useSelector((state: any) => state.user);

  const handleSearch = (searchString: string) => {
    setSearch(searchString);
  };

  const filtredItems = useMemo(() => {
    if (!search) return DunkinCategory;
    let items: MenuItem[] = [];
    const returnedData: Category[] = [];
    DunkinCategory.forEach((DunkinCategory: any) => {
      DunkinCategory.menuItems.forEach((menuItem: MenuItem) => {
        if (menuItem.name.toLowerCase().includes(search.toLowerCase())) {
          items.push(menuItem);
        }
      });
      if (items.length === 0) return;
      returnedData.push({ ...DunkinCategory, menuItems: items });
      items = [];
    });
    return returnedData;
  }, [search, categories]);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getCategoryData();
      console.log(response)
      dispatch(setCategories(response));
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching getCategoryData:", error);
    }
  };


  useEffect(() => {
    if (categories.length === 0) {
      fetchData();
    } else {
      setIsLoading(false);
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      dispatch(setCategories([]));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const fetchCart = async (userID: string) => {
    let existingCart: any = {};
    if (userID.length > 0) {
      const response = await getCart(userID);
      const onlineCart = response.length === 0 ? {} : response[0];
      console.log(`onlineCart: ${JSON.stringify(onlineCart)}`)
      const localCart = getLocalCart();
      if (onlineCart.orderedItems?.length > 0) {
        updateOfflineCart([], 0);
        existingCart = mergeLocalAndOnlineCart(localCart, onlineCart);
        if (onlineCart.totalPrice !== existingCart.totalPrice) {
          // Cart was changed after the merge, update the online cart
          await updateOnlineCart(existingCart.orderedItems, existingCart.totalPrice, userID);
        }
      } else {
        existingCart = localCart;
      }
    } else {
      existingCart = getLocalCart();
    }
    const totalQuantity = getCartItemsQuantity(existingCart);
    dispatch(setCart(existingCart?.orderedItems || []));
    dispatch(setCartQuantity(totalQuantity));
    dispatch(setCartTotal(existingCart?.totalPrice || 0));
  };

  useEffect(() => {
    const userID = user ? user.id : "";
    fetchCart(userID);
  }, [user]);

  function scrollToCategory(categoryId:any) {
    const targetElement = document.getElementById(categoryId);
    if (targetElement) {
      const targetPosition = targetElement.offsetTop -  165;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  }

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     dispatch(setSplashScreen(false));
  //   }, 3000);

  //   return () => clearTimeout(timeout);

  // }, []);

  // if (splashScreen) {
  //   return (<SplashScreen />)
  // }

  return (


    <SkeletonTheme baseColor="#ff6e0c" highlightColor="#ff6e0c">
      {isLoading ? (
        <MySkeleton />
      ) : (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "3rem",
            position: "relative",
          }}
        >
          <Sidebar />
          <Voucherheader />
          {/* <img src="./assets/DUNKIN/MenuItems/Bakery/(1).jpg"/> */}
          <Searchinput handleSearch={handleSearch} />

          <Stack sx={{ width: "100%", padding: "5px 5px" }} direction="row">
            <Typography
              variant="h1"
              color={theme.palette.primary.main}
              fontSize={"18px"}
              fontWeight={"500"}
            >
              Categories
            </Typography>
          </Stack>

          <Stack
            direction="row"
            sx={{
              overflowX: "auto",
              overflowY: "hidden",
              paddingTop: ".5rem",
              paddingBottom: ".5rem",
              backgroundColor: theme.palette.background.default,
              width: "100%",
              position: "sticky",
              top: "4rem",
              zIndex: 4,
            }}
            justifyContent={"start"}
          >
            {DunkinCategory.map((DunkinCategory: any, index: any) => (
              <Box onClick={() => scrollToCategory(DunkinCategory.id)} key={DunkinCategory.id} >
              <Categortycontainer
                image={DunkinCategory.image}
              />
            </Box>
            ))}
          </Stack>

          {filtredItems && filtredItems.length > 0 ? (
            filtredItems.map((DunkinCategory: any, index: any) =>
              DunkinCategory.menuItems && DunkinCategory.menuItems.length > 0 ? (
                <Box
                  id={DunkinCategory.id}
                  key={DunkinCategory.id}
                  sx={{ width: "100%" }}
                >
                  <Typography
                    mb={"5px"}
                    ml={"14px"}
                    fontSize={"18px"}
                    fontWeight={"500px"}
                    color={theme.palette.primary.main}
                    variant="h6"
                  >
                    {capitalization(DunkinCategory.name)}
                  </Typography>
                  <Grid container sx={{ marginBottom: "1rem" }}>
                    {DunkinCategory.menuItems.map((menuItem: any) => (
                      <Grid item xs={6} sm={6} md={3} key={menuItem.id} >
                        <Foodcard
                          name={capitalization(menuItem.name)}
                          image={menuItem.image}
                          description={capitalization(
                            menuItem.description.substring(0, 30)
                          )}
                          newItem={menuItem.newItem}
                          prices={menuItem.prices}
                          item={menuItem}
                          categoryID={DunkinCategory.id}
                        />
                        {/* </Link> */}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : null
            )
          ) : (
            <Typography variant="body1">
              No matching categories found.
            </Typography>
          )}
        </Container>
      )}


    </SkeletonTheme>


  );
};

export default CategoryPage;
