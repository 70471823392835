import { useEffect, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTheme } from "@mui/material/styles";
import {
  setCart,
  setCartQuantity,
  setCartTotal,
  setSelectedItem,
} from "../../state/index";
import {
  updateOnlineCart,
  decrementOrRemoveItem,
  countItemVersions,
  updateOfflineCart,
} from "../../services/cartUtils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface CounterComponentProps {
  itemID: string;
  categoryID: string;
  item: any;
}
const CounterComponent = ({
  itemID,
  categoryID,
  item,
}: CounterComponentProps) => {
  const [value, setValue] = useState<number>(0);
  const [disableBtns, setDisableBtns] = useState<boolean>(false);
  const theme = useTheme();
  const items: any[] = useSelector(
    (state: any) => state.cart.orderedItems || []
  );
  const totalQuantity = useSelector((state: any) => state.cart.quantity || 0);
  const totalPrice = useSelector((state: any) => state.cart.total || 0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);

  const updateCartState = (
    updatedCartItems: any,
    newQuantity: number,
    newTotalPrice: number
  ) => {
    dispatch(setCart(updatedCartItems));
    dispatch(setCartQuantity(newQuantity));
    dispatch(setCartTotal(newTotalPrice));
  };

  const handleIncrement = async () => {
    setDisableBtns(true);
    const index = items.findIndex((item) => item.menuItemID === itemID);
    if (index < 0) {
      // item is new so redirect the user to the item page
      dispatch(setSelectedItem(item));
      navigate(`/menu/${categoryID}/${itemID}`);
    } else {
      // item already exists in the cart
      const itemVersions = countItemVersions(itemID, items);
      if (itemVersions > 1) {
        // if itemVersions > 1 redirect the user to the itemPage to choose which choices and price he wants to add
        dispatch(setSelectedItem(item));
        navigate(`/menu/${categoryID}/${itemID}`);
      } else {
        // else there is one cartItem with this itemID then inc it
        const updatedItems = [...items];
        const updatedItem = {
          ...updatedItems[index],
          quantity: updatedItems[index].quantity + 1,
        };
        updatedItems[index] = updatedItem;
        const newQuantity = totalQuantity + 1;
        const newTotalPrice = totalPrice + updatedItem.price;
        if (user) {
          await updateOnlineCart(updatedItems, newTotalPrice, user.id);
        } else {
          updateOfflineCart(updatedItems, newTotalPrice);
        }
        updateCartState(updatedItems, newQuantity, newTotalPrice);
        setValue((prev) => prev + 1);
      }
    }

    setDisableBtns(false);
  };

  const handleDecrement = async () => {
    setDisableBtns(true);

    const index = items.findIndex((item) => item.menuItemID === itemID);
    if (index < 0) {
      setDisableBtns(false);
      return;
    } else {
      const itemVersions = countItemVersions(itemID, items);
      if (itemVersions > 1) {
        navigate("/cart");
      } else {
        const updatedItems = [...items];
        const updatedItem = {
          ...updatedItems[index],
          quantity: updatedItems[index].quantity - 1,
        };
        updatedItems[index] = updatedItem;
        const finalUpdatedItems = decrementOrRemoveItem(
          updatedItems,
          updatedItem,
          index
        );
        const newQuantity = totalQuantity - 1;
        const newTotalPrice = totalPrice - updatedItem.price;
        if (user) {
          await updateOnlineCart(updatedItems, newTotalPrice, user);
        } else {
          updateOfflineCart(updatedItems, newTotalPrice);
        }
        updateCartState(finalUpdatedItems, newQuantity, newTotalPrice);
        setValue((prev) => prev - 1);
      }
    }

    setDisableBtns(false);
  };

  useEffect(() => {
    // put each item quantity as its value
    const index = items.findIndex((item) => item.menuItemID === itemID);
    if (index < 0) return;
    const versions = countItemVersions(itemID, items);
    const item = items[index];
    if (versions > 1) return;
    setValue(item.quantity);
  }, []);

  return (
    <Stack direction="row" gap={1}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          height: "19px",
          width: "19px",
          border: "1px solid ",
          borderRadius: "3px",
          borderColor: '#ff6e0c',
         
        }}
      >
        <Button onClick={handleDecrement} disabled={disableBtns}>
          <RemoveIcon
            sx={{
              color:'#ff6e0c',
              height: "15px",
              width: "15px",
            }}
          />
        </Button>
      </Box>
      <Box
        sx={{
          color: theme.palette.primary.main,
          fontSize: "15px",
          fontWeight: "500px",
        }}
      >
        {value}
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          height: "19px",
          width: "19px",
          borderRadius: "3px",
          backgroundColor: '#ff6e0c',
        }}
      >
        <Button onClick={handleIncrement} disabled={disableBtns}>
          <AddIcon sx={{ height: "15px", width: "15px" }} />
        </Button>
      </Box>
    </Stack>
  );
};

export default CounterComponent;
