import { EndPoints } from "../constants/Enums";

async function createOnlineOrder(
  cartID: string,
  conceptID: string,
  userID: string,
  userName: string,
  orderedItems: any,
  specialRequest: string,
  OrderStatus: string,
  orderType: string,
  orderTime: string,
  totalPrice: number,
  tableId: string,
  createdAt: string,
  tableName: string,
  parentOrder: string,
  childrenStatus: string[]
) {
  try {
    const operationId = 4;
    const requestBody = {
      operationId,
      cartID,
      conceptID,
      userID,
      userName,
      orderedItems,
      specialRequest,
      OrderStatus,
      orderType,
      orderTime,
      totalPrice,
      tableId,
      createdAt,
      tableName,
      parentOrder,
      childrenStatus,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(EndPoints.getCategoryData, options);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.error("Error creating online order :", error);
    throw error;
  }
}

export default createOnlineOrder;
