export const capitalization = (str:string) => {
  if (!str || typeof str !== "string") return ""; // Check if str is null, undefined, or not a string
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function formatDate(dateString: Date) {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };

  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate;
}



