import WidgetsIcon from "@mui/icons-material/Widgets";
import {
    IconButton,
    Grid,
    Box,
    Typography,
    useTheme,
    Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EmptyCart = () => {
    const theme = useTheme();
    const themeMode = useSelector((state: any) => state.mode);
    const navigate = useNavigate();


    const handleOnClick = () => {
        navigate("/");
    }

    return (
        <Grid
        container
        justifyContent="center"
        alignItems='center'
        sx={{
          minHeight: "90vh",
        }}
      >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
            >
                <img
                    src="./assets/emptyCartLogo.png"
                    alt="emptyCartLogo"
                    style={{ marginBottom: '0.5rem' }}
                />
                <Typography
                    variant="h6"
                    color="primary.main"
                    sx={{
                        fontFamily: 'Inter',
                        marginTop: '0.5rem',
                    }}
                >
                    Your cart is empty.
                </Typography>
                <Typography
                    variant="body1"
                    color="#D3D3D3"
                    sx={{
                        fontFamily: 'Inter',
                        marginTop: '0.2rem',
                
                    }}
                >
                    Looks like you haven't added anything to your cart yet.
                </Typography>
                <Button
                    variant="contained"

                   sx={{ marginTop:'0.5rem',
                    backgroundColor:"#ff189c"}}
                    onClick={handleOnClick}
                
                >
                    Back to menu
                </Button>
            </Box>
        </Grid>
    )
}

export default EmptyCart;
