import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom';
import CategoryPage from "./containers/caegorycontainer";
import Menu from "./containers/menu";
import Cart from "./components/cart/Cart";
import LoginPage from "./components/login/LoginPage";
import Register from "./components/register/Register";
import SplashScreen from "./components/splashScreen/SplashScreen";
import { themeSettings } from "./theme/theme";
import { createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import UserPage from "./components/userPage/UserPage";
import About from "./components/about/About";
import OrderHistory from "./components/orderHistory/OrderHistory";
import Contact from "./components/contact/Contact";
import OrderDetails from "./components/orderDetails/OrderDetails";
import OrderTrack from "./components/ordertrack/OrderTrack";
import Notifications from "./components/notifications/Notifications";
import AdressPage from "./components/address/AdressPage";
import NewAddress from "./components/address/NewAddress";
import Navbar from "./components/Nav/Navbar";
import { fetchUserAttributes } from "aws-amplify/auth";
import getUser from "./services/getUser";
import createUser from "./services/createUser";
import { setLogin } from "./state";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);

  const [loading, setLoading] = useState(true);
  const mode = useSelector((state: any) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const location = useLocation();

  const showNavbar = !["/login", "/register","/orders","/login/"].includes(location.pathname);


  const fetchUser=async()=>{
    if(!user)
    {
      try
      {
        let loggedInUser= await fetchUserAttributes()
        if(loggedInUser)
        {
          let group:any=null
          if( loggedInUser.identities && loggedInUser.identities.toString().includes("Google") ){group="Google"}
          else if( loggedInUser.identities &&  loggedInUser.identities.toString().includes("Apple")){group="Apple"}
          else{group="Cognito"}
          let currentUser= await getUser(loggedInUser.sub)
          if(!currentUser)
          {
            let newUser= await createUser(loggedInUser,group)
            dispatch(setLogin({ user: newUser }));
          }
          else
          {
            dispatch(setLogin({ user: currentUser }));
          }
        }
      } catch{
        console.log("not logged in")
      }
    }
  }


  useEffect(() => {
    fetchUser();
  }, [user]);

  return (

      <ThemeProvider theme={theme}>
        <CssBaseline />
          <>
            <Routes>
              <Route path="/" element={<CategoryPage />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/menu/:categoryId/:itemId" element={<Menu />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={<UserPage />} />
              <Route path="/about" element={<About />} />
              <Route path="/orders" element={<OrderHistory />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/orderDetails" element={<OrderDetails />} />
              <Route path="/orderTrack" element={<OrderTrack />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/address" element={<AdressPage />} />
              <Route path="/newAddress" element={<NewAddress />} />
              
            </Routes>
            {showNavbar && <Navbar />}
          </>
      </ThemeProvider>
 
  );
};

export default App;
