import { Container, Stack, Box, Grid } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useState, useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "./shimmer.css"; 
function MySkeleton() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Container>
    <SkeletonTheme baseColor="#c0c0c0" highlightColor="#e0e0e0">
        {/* <SkeletonTheme baseColor="#FFDEAD" highlightColor="#F0F8FF"> */}
        <Grid container justifyContent="space-between" sx={{ marginTop: "2rem" }}>
          <Skeleton  className="shimmer" height={15} width={15} />
          <Skeleton  className="shimmer" height={15} width={15} />
        </Grid>

        <Box
          sx={{
            width: "100%",
            marginTop: "10px",
            overflowX: "auto",
            marginTop: "1rem",
          }}
        >
          <Skeleton  className="shimmer" height={150} />
        </Box>
        {/* searchbar */}
        <Box
          sx={{
            width: "100%",
            borderRadius: "5px",
            marginTop: "1rem",
          }}
        >
          <Skeleton  className="shimmer" height={30} />
        </Box>
        {/* circles */}
        <Stack
          direction="row"
          sx={{
            overflow: "auto",
            width: "90%",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
          justifyContent={"space-between"}
        >
          <Skeleton  className="shimmer" circle width={screenWidth * 0.2} height={screenWidth * 0.2} />
          <Skeleton  className="shimmer" circle width={screenWidth * 0.2} height={screenWidth * 0.2} />
          <Skeleton  className="shimmer" circle width={screenWidth * 0.2} height={screenWidth * 0.2} />
          <Skeleton  className="shimmer" circle width={screenWidth * 0.2} height={screenWidth * 0.2} />
        </Stack>
        {/* boxes-1 */}
        {/* <Stack
          direction="row"
          sx={{
            overflow: "auto",
            width: "100%",
            marginTop: "1rem",
            marginLeft: "1rem",
            borderRadius: "60px"
          }}
          justifyContent={"space-between"}
        >

          <Skeleton width={screenWidth * 0.4} height={screenWidth * 0.4} />

        </Stack>
        <Stack
          direction="row"
          sx={{
            overflow: "auto",
            width: "90%",
            marginTop: "1rem",
            marginLeft: "1rem",
           
          }}
          justifyContent={"space-between"}
        >

          <Skeleton width={screenWidth * 0.4} height={screenWidth * 0.4} />

        </Stack>
        <Stack
          direction="row"
          sx={{
            overflow: "auto",
            width: "90%",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
          justifyContent={"space-between"}
        >
          <Skeleton width={screenWidth * 0.4} height={10} />
          <Skeleton width={screenWidth * 0.4} height={10} />
        </Stack> */}

        <Stack
          direction="row"
          sx={{
            overflow: "auto",
            width: "90%",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
          justifyContent={"space-between"}
        >
          <Skeleton  className="shimmer" width={screenWidth * 0.4} height={screenWidth * 0.4} />
          <Skeleton  className="shimmer" width={screenWidth * 0.4} height={screenWidth * 0.4} />
        </Stack>

        <Stack
          direction="row"
          sx={{
            overflow: "auto",
            width: "90%",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
          justifyContent={"space-between"}
        >
          <Skeleton  className="shimmer" width={screenWidth * 0.4} height={10} />
          <Skeleton  className="shimmer" width={screenWidth * 0.4} height={10} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            overflow: "auto",
            width: "90%",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
          justifyContent={"space-between"}
        >
          <Skeleton  className="shimmer" width={screenWidth * 0.4} height={screenWidth * 0.4} />
          <Skeleton  className="shimmer" width={screenWidth * 0.4} height={screenWidth * 0.4} />
        </Stack>

        <Stack
          direction="row"
          sx={{
            overflow: "auto",
            width: "90%",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
          justifyContent={"space-between"}
        >
          <Skeleton  className="shimmer" width={screenWidth * 0.4} height={10} />
          <Skeleton  className="shimmer" width={screenWidth * 0.4} height={10} />
        </Stack>
      </SkeletonTheme>
    </Container>
  );
}

export default MySkeleton;
