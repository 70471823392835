export const DunkinCategory = [
  {
    id: "686d6e33-1571-4f60-95b9-2aff8b40a313",
    conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
    preparationAreaID: "",
    subCategory: "5071865e-2faa-4fcc-9827-3c297fc51065",
    name: "Hot Beverages",
    guestView: false,
    guestOrder: false,
    staffOrder: false,
    image: "../assets/DUNKIN/Categories/(7).jpg",
    precedence: "0",
    deleted: "0",
    createdAt: "2023-11-30",
    createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
    createdByName: "Admin",
    multiLanguages: '[["English-name","burger"],["Arabic-name","برجر"]]',
    updatedAt: "2023-12-07T07:51:25.602Z",
    _version: 5,
    _deleted: null,
    _lastChangedAt: 1701935485644,
    __typename: "Category",
    menuItems: [
      {
        id: "711d3e88-709e-4124-b97a-580136d6eb56",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "Cappucino",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: "./assets/DUNKIN/MenuItems/Bakery/Cappuccino.jpg",
        categoryID: "686d6e33-1571-4f60-95b9-2aff8b40a313",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "9ea65668-8557-49c0-a1e9-7ef32f64ac46",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "S",
            price: 10,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T17:53:31.517Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626011548,
            __typename: "Price",
          },
          {
            id: "9ea65668-8557-49c0-a1e9-7ef32f64ac46",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "M",
            price: 12,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T17:53:31.517Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626011548,
            __typename: "Price",
          },
          {
            id: "9ea65668-8557-49c0-a1e9-7ef32f64ac46",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "L",
            price: 14,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T17:53:31.517Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626011548,
            __typename: "Price",
          },
        ],
        choiceGroups: [
          {
            id: "240fc5c3-1a3b-4a17-9737-22abd9fa4b65",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "Milk",
            required: false,
            maxNumberOfChoices: 0,
            minNumberOfChoices: 0,
            precedence: "0",
            type: null,
            choices: [
              {
                id: "3be81690-a7c0-441e-8d71-82b7d7bf7b5b",
                conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
                name: "Full fat Milk",
                price: 0,
                choiceGroupID: "240fc5c3-1a3b-4a17-9737-22abd9fa4b65",
                choiceGroupName: null,
                deleted: "0",
                createdAt: "11/17/2023, 1:38:34 AM",
                createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
                createdByName: "Admin",
                multiLanguages: null,
                symphonyID: "12",
                updatedAt: "2023-11-27T09:37:14.803Z",
                _version: 9,
                _deleted: null,
                _lastChangedAt: 1701077834817,
                __typename: "Choice",
              },
              {
                id: "41100a5b-584e-4018-989d-f02038be645e",
                conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
                name: "Low fat Milk",
                price: 0,
                choiceGroupID: "d01e7fc7-5fdf-49ee-972c-dc5608c48122",
                choiceGroupName: null,
                deleted: "0",
                createdAt: "11/24/2023, 1:29:35 AM",
                createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
                createdByName: "Admin",
                multiLanguages: null,
                symphonyID: "0",
                updatedAt: "2023-11-26T15:57:31.228Z",
                _version: 7,
                _deleted: null,
                _lastChangedAt: 1701014251262,
                __typename: "Choice",
              },
            ],
            deleted: "0",
            createdAt: "11/24/2023, 1:21:57 AM",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            multiLanguages: null,
            updatedAt: "2023-12-03T19:09:35.971Z",
            _version: 58,
            _deleted: null,
            _lastChangedAt: 1701630575986,
            __typename: "ChoiceGroups",
          },
        ],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-12-03",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages:
          '[["English-name","Chilli"],["English-description","Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]]',
        quantityOrdered: 0,
        updatedAt: "2023-12-07T09:51:01.878Z",
        _version: 6,
        _deleted: null,
        _lastChangedAt: 1701942661894,
        __typename: "MenuItem",
      },
      {
        id: "4b58e7bd-7b55-4f22-a5f3-2015dfadd9f6",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "Latte",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: "./assets/DUNKIN/MenuItems/Bakery/Latte.jpg",
        categoryID: "686d6e33-1571-4f60-95b9-2aff8b40a313",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "c67e9abc-86cb-42bb-9358-b3742405a8b0",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "S",
            price: 10,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T17:52:39.610Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701625959614,
            __typename: "Price",
          },
          {
            id: "c67e9abc-86cb-42bb-9358-b3742405a8b0",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "M",
            price: 12,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T17:52:39.610Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701625959614,
            __typename: "Price",
          },
          {
            id: "c67e9abc-86cb-42bb-9358-b3742405a8b0",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "M",
            price: 14,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T17:52:39.610Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701625959614,
            __typename: "Price",
          },
        ],
        choiceGroups: [
          {
            id: "240fc5c3-1a3b-4a17-9737-22abd9fa4b65",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "Milk",
            required: false,
            maxNumberOfChoices: 0,
            minNumberOfChoices: 0,
            precedence: "0",
            type: null,
            choices: [
              {
                id: "3be81690-a7c0-441e-8d71-82b7d7bf7b5b",
                conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
                name: "Full fat Milk",
                price: 0,
                choiceGroupID: "240fc5c3-1a3b-4a17-9737-22abd9fa4b65",
                choiceGroupName: null,
                deleted: "0",
                createdAt: "11/17/2023, 1:38:34 AM",
                createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
                createdByName: "Admin",
                multiLanguages: null,
                symphonyID: "12",
                updatedAt: "2023-11-27T09:37:14.803Z",
                _version: 9,
                _deleted: null,
                _lastChangedAt: 1701077834817,
                __typename: "Choice",
              },
              {
                id: "41100a5b-584e-4018-989d-f02038be645e",
                conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
                name: "Low fat Milk",
                price: 0,
                choiceGroupID: "d01e7fc7-5fdf-49ee-972c-dc5608c48122",
                choiceGroupName: null,
                deleted: "0",
                createdAt: "11/24/2023, 1:29:35 AM",
                createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
                createdByName: "Admin",
                multiLanguages: null,
                symphonyID: "0",
                updatedAt: "2023-11-26T15:57:31.228Z",
                _version: 7,
                _deleted: null,
                _lastChangedAt: 1701014251262,
                __typename: "Choice",
              },
            ],
            deleted: "0",
            createdAt: "11/24/2023, 1:21:57 AM",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            multiLanguages: null,
            updatedAt: "2023-12-03T19:09:35.971Z",
            _version: 58,
            _deleted: null,
            _lastChangedAt: 1701630575986,
            __typename: "ChoiceGroups",
          },
        ],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-12-03",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: null,
        quantityOrdered: 0,
        updatedAt: "2023-12-03T19:15:25.560Z",
        _version: 5,
        _deleted: null,
        _lastChangedAt: 1701630925598,
        __typename: "MenuItem",
      },
    ],
  },
  {
    id: "38536dae-844c-424c-8d10-1b41c0d86dc7",
    conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
    preparationAreaID: "",
    subCategory: "5071865e-2faa-4fcc-9827-3c297fc51065",
    name: "Cold Beverages",
    guestView: false,
    guestOrder: false,
    staffOrder: false,
    image: "../assets/DUNKIN/Categories/(5).jpg",
    precedence: "0",
    deleted: "0",
    createdAt: "2023-11-30",
    createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
    createdByName: "Admin",
    multiLanguages: '[["English-name","shawerma"],["Arabic-name","شاورما"]]',
    updatedAt: "2023-12-07T07:51:53.972Z",
    _version: 5,
    _deleted: null,
    _lastChangedAt: 1701935514013,
    __typename: "Category",
    menuItems: [
      {
        id: "773effe0-3473-4cc6-a013-9e87996313ee",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: " Iced Latte",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: "../assets/DUNKIN/MenuItems/Bakery/(10).jpg",
        categoryID: "38536dae-844c-424c-8d10-1b41c0d86dc7",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "37e55a13-e331-4251-a1c9-4d6522c2ddaf",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "M",
            price: 12,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T18:08:07.098Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626887119,
            __typename: "Price",
          },
          {
            id: "37e55a13-e331-4251-a1c9-4d6522c2ddaf",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "L",
            price: 14,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T18:08:07.098Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626887119,
            __typename: "Price",
          },
        ],
        choiceGroups: [
          {
            id: "240fc5c3-1a3b-4a17-9737-22abd9fa4b65",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "Milk",
            required: false,
            maxNumberOfChoices: 0,
            minNumberOfChoices: 0,
            precedence: "0",
            type: null,
            choices: [
              {
                id: "3be81690-a7c0-441e-8d71-82b7d7bf7b5b",
                conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
                name: "Full fat Milk",
                price: 0,
                choiceGroupID: "240fc5c3-1a3b-4a17-9737-22abd9fa4b65",
                choiceGroupName: null,
                deleted: "0",
                createdAt: "11/17/2023, 1:38:34 AM",
                createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
                createdByName: "Admin",
                multiLanguages: null,
                symphonyID: "12",
                updatedAt: "2023-11-27T09:37:14.803Z",
                _version: 9,
                _deleted: null,
                _lastChangedAt: 1701077834817,
                __typename: "Choice",
              },
              {
                id: "41100a5b-584e-4018-989d-f02038be645e",
                conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
                name: "Low fat Milk",
                price: 0,
                choiceGroupID: "d01e7fc7-5fdf-49ee-972c-dc5608c48122",
                choiceGroupName: null,
                deleted: "0",
                createdAt: "11/24/2023, 1:29:35 AM",
                createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
                createdByName: "Admin",
                multiLanguages: null,
                symphonyID: "0",
                updatedAt: "2023-11-26T15:57:31.228Z",
                _version: 7,
                _deleted: null,
                _lastChangedAt: 1701014251262,
                __typename: "Choice",
              },
            ],
            deleted: "0",
            createdAt: "11/24/2023, 1:21:57 AM",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            multiLanguages: null,
            updatedAt: "2023-12-03T19:09:35.971Z",
            _version: 58,
            _deleted: null,
            _lastChangedAt: 1701630575986,
            __typename: "ChoiceGroups",
          },
        ],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-12-03",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: null,
        quantityOrdered: 0,
        updatedAt: "2023-12-03T19:16:07.755Z",
        _version: 3,
        _deleted: null,
        _lastChangedAt: 1701630967769,
        __typename: "MenuItem",
      },
      {
        id: "1e214adb-070d-4308-b1dd-a2ccd5bfaa88",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "Iced Caramel Machiatto",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: "../assets/DUNKIN/MenuItems/Bakery/(12).jpg",
        categoryID: "38536dae-844c-424c-8d10-1b41c0d86dc7",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "a118bc8b-6c21-4bac-9728-ae6f5db99878",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "M",
            price: 14,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T18:07:22.200Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626842203,
            __typename: "Price",
          },
          {
            id: "a118bc8b-6c21-4bac-9728-ae6f5db99878",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "L",
            price: 17,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T18:07:22.200Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626842203,
            __typename: "Price",
          },
        ],
        choiceGroups: [
          {
            id: "240fc5c3-1a3b-4a17-9737-22abd9fa4b65",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "Milk",
            required: false,
            maxNumberOfChoices: 0,
            minNumberOfChoices: 0,
            precedence: "0",
            type: null,
            choices: [
              {
                id: "3be81690-a7c0-441e-8d71-82b7d7bf7b5b",
                conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
                name: "Full fat Milk",
                price: 0,
                choiceGroupID: "240fc5c3-1a3b-4a17-9737-22abd9fa4b65",
                choiceGroupName: null,
                deleted: "0",
                createdAt: "11/17/2023, 1:38:34 AM",
                createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
                createdByName: "Admin",
                multiLanguages: null,
                symphonyID: "12",
                updatedAt: "2023-11-27T09:37:14.803Z",
                _version: 9,
                _deleted: null,
                _lastChangedAt: 1701077834817,
                __typename: "Choice",
              },
              {
                id: "41100a5b-584e-4018-989d-f02038be645e",
                conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
                name: "Low fat Milk",
                price: 0,
                choiceGroupID: "d01e7fc7-5fdf-49ee-972c-dc5608c48122",
                choiceGroupName: null,
                deleted: "0",
                createdAt: "11/24/2023, 1:29:35 AM",
                createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
                createdByName: "Admin",
                multiLanguages: null,
                symphonyID: "0",
                updatedAt: "2023-11-26T15:57:31.228Z",
                _version: 7,
                _deleted: null,
                _lastChangedAt: 1701014251262,
                __typename: "Choice",
              },
            ],
            deleted: "0",
            createdAt: "11/24/2023, 1:21:57 AM",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            multiLanguages: null,
            updatedAt: "2023-12-03T19:09:35.971Z",
            _version: 58,
            _deleted: null,
            _lastChangedAt: 1701630575986,
            __typename: "ChoiceGroups",
          },
        ],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-12-03",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: "[]",
        quantityOrdered: 0,
        updatedAt: "2023-12-07T12:51:21.572Z",
        _version: 5,
        _deleted: null,
        _lastChangedAt: 1701953481592,
        __typename: "MenuItem",
      },
    ],
  },
  {
    id: "642709fc-525a-4d7f-a062-9b0c95ae9a4d",
    conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
    preparationAreaID: "",
    subCategory: "5071865e-2faa-4fcc-9827-3c297fc51065",
    name: "Boxes",
    guestView: false,
    guestOrder: false,
    staffOrder: false,
    image: "../assets/DUNKIN/Categories/(3).jpg",
    precedence: "0",
    deleted: "0",
    createdAt: "2023-11-23",
    createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
    createdByName: "Admin",
    multiLanguages: '[["English-name","hot dogs"],["Arabic-name","نقانق"]]',
    updatedAt: "2023-12-07T07:52:23.373Z",
    _version: 6,
    _deleted: null,
    _lastChangedAt: 1701935543415,
    __typename: "Category",
    menuItems: [
      {
        id: "a18fee81-c143-4c72-b6f0-5314bb56f184",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "Dunkin Box Original Blend",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: "../assets/DUNKIN/MenuItems/Bakery/(15).jpg",
        categoryID: "642709fc-525a-4d7f-a062-9b0c95ae9a4d",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "8716cfc5-3f46-49e8-b6c5-477b33be1014",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "20 Oz",
            price: 20,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T17:56:58.255Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626218279,
            __typename: "Price",
          },
          {
            id: "8716cfc5-3f46-49e8-b6c5-477b33be1014",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "40 Oz",
            price: 36,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T17:56:58.255Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626218279,
            __typename: "Price",
          },
          {
            id: "8716cfc5-3f46-49e8-b6c5-477b33be1014",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "112 Oz",
            price: 80,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T17:56:58.255Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626218279,
            __typename: "Price",
          },
        ],
        choiceGroups: [],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-12-03",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: null,
        quantityOrdered: 0,
        updatedAt: "2023-12-03T17:57:34.436Z",
        _version: 2,
        _deleted: null,
        _lastChangedAt: 1701626254470,
        __typename: "MenuItem",
      },
      {
        id: "746cad5a-5de4-4de9-a285-227e8824f5b1",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "pizza",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque modi aspernatur natus dolorem, repellat placeat odit voluptate quis sit earum possimus est rerum autem qui illum sequi maxime. Debitis, culpa!",
        image: "../assets/DUNKIN/MenuItems/Bakery/(15).jpg",
        categoryID: "642709fc-525a-4d7f-a062-9b0c95ae9a4d",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "767fb410-be45-4f73-869c-b28d051b99b7",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "ff",
            price: 100,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-11-23",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-11-23T14:32:35.375Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1700749955401,
            __typename: "Price",
          },
        ],
        choiceGroups: [],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-11-23",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: null,
        quantityOrdered: 0,
        updatedAt: "2023-11-27T09:54:06.079Z",
        _version: 9,
        _deleted: null,
        _lastChangedAt: 1701078846093,
        __typename: "MenuItem",
      },
    ],
  },
  {
    id: "26f51bb0-8659-462c-8ccb-00932f478491",
    conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
    preparationAreaID: "",
    subCategory: "5071865e-2faa-4fcc-9827-3c297fc51065",
    name: "Donuts",
    guestView: false,
    guestOrder: false,
    staffOrder: false,
    image: "../assets/DUNKIN/Categories/(6).jpg",
    precedence: "0",
    deleted: "0",
    createdAt: "2023-11-26",
    createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
    createdByName: "Admin",
    multiLanguages: '[["English-name","appetizers"],["Arabic-name","مقبلات"]]\n',
    updatedAt: "2023-12-07T07:52:48.994Z",
    _version: 13,
    _deleted: null,
    _lastChangedAt: 1701935569010,
    __typename: "Category",
    menuItems: [
      {
        id: "96c70318-6278-429c-af36-234248dcd204",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "Glazed Donut",
        description: "",
        image: "../assets/DUNKIN/MenuItems/Bakery/Glazed Donut.jpg",

        categoryID: "26f51bb0-8659-462c-8ccb-00932f478491",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "5ed2f2c4-7d20-4126-8673-00d6582b4781",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "basic",
            price: 5,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T18:10:48.739Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701627048764,
            __typename: "Price",
          },
        ],
        choiceGroups: [],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-12-03",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: '[["English-name","burger"],["Arabic-name","برجر"],["English-description","burger"],["Arabic-description","برجر"]]',
        quantityOrdered: 0,
        updatedAt: "2023-12-03T18:10:50.582Z",
        _version: 1,
        _deleted: null,
        _lastChangedAt: 1701627050613,
        __typename: "MenuItem",
      },
      {
        id: "6d89c513-7ee9-446a-8d12-3d696d25852f",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "Choco Frosted",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque modi aspernatur natus dolorem, repellat placeat odit voluptate quis sit earum possimus est rerum autem qui illum sequi maxime. Debitis, culpa!",
          image: "../assets/DUNKIN/MenuItems/Bakery/Choco Frosted.jpg",
        categoryID: "26f51bb0-8659-462c-8ccb-00932f478491",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "c974f6cc-1310-4455-b0a3-8efb9e8ad2e9",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "basic",
            price: 5,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-11-14",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-11-13T22:28:59.538Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1699914539541,
            __typename: "Price",
          },
         
        ],
        choiceGroups: [
         
        ],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: true,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-11-14",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: null,
        quantityOrdered: 0,
        updatedAt: "2023-11-27T09:55:05.417Z",
        _version: 5,
        _deleted: null,
        _lastChangedAt: 1701078905436,
        __typename: "MenuItem",
      },
      {
        id: "00b0e521-a58a-4040-8c73-102afe7dc0e0",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "Blueberry White Cheesecake  ",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque modi aspernatur natus dolorem, repellat placeat odit voluptate quis sit earum possimus est rerum autem qui illum sequi maxime. Debitis, culpa!",
          image: "../assets/DUNKIN/MenuItems/Bakery/Blueberry White Cheescake.jpg",
        categoryID: "26f51bb0-8659-462c-8ccb-00932f478491",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "7280b0bf-4021-4900-9013-b46466a64494",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "basic",
            price: 6,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-11-14",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-11-13T22:39:59.211Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1699915199214,
            __typename: "Price",
          },
          {
            id: "7e142f25-766e-4d5e-ad16-0abd6ebc36ee",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "large ",
            price: 70,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-11-14",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-11-13T22:40:15.179Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1699915215182,
            __typename: "Price",
          },
        ],
        choiceGroups: [
          
        ],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-11-14",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: null,
        quantityOrdered: 0,
        updatedAt: "2023-11-27T09:56:02.615Z",
        _version: 8,
        _deleted: null,
        _lastChangedAt: 1701078962651,
        __typename: "MenuItem",
      },
      {
        id: "5314c63c-a317-42b7-b334-ff4e9feea595",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "Pistachio Cheesecake ",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque modi aspernatur natus dolorem, repellat placeat odit voluptate quis sit earum possimus est rerum autem qui illum sequi maxime. Debitis, culpa!",
          image: "../assets/DUNKIN/MenuItems/Bakery/17012023080015~11012023074406_2C3A7.jpg",
        categoryID: "26f51bb0-8659-462c-8ccb-00932f478491",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "869e469f-c26e-4fcd-8d81-99055ef297b5",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "basic",
            price: 6,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-11-14",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-11-13T22:29:52.736Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1699914592769,
            __typename: "Price",
          },
          
        ],
        choiceGroups: [
    
        ],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-11-14",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: null,
        quantityOrdered: 0,
        updatedAt: "2023-11-27T09:55:25.649Z",
        _version: 25,
        _deleted: null,
        _lastChangedAt: 1701078925683,
        __typename: "MenuItem",
      },
     
    ],
  },
  {
    id: "4f74794c-9972-4902-8a28-9f314160e7cd",
    conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
    preparationAreaID: "",
    subCategory: "5071865e-2faa-4fcc-9827-3c297fc51065",
    name: "Sandwiches",
    guestView: false,
    guestOrder: false,
    staffOrder: false,
    image: "../assets/DUNKIN/Categories/(1).jpg",
    precedence: "0",
    deleted: "0",
    createdAt: "2023-11-30",
    createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
    createdByName: "Admin",
    multiLanguages: '[["English-name","fried ckicken"],["Arabic-name","دجاج مقلي"]]',
    updatedAt: "2023-12-07T07:53:11.701Z",
    _version: 5,
    _deleted: null,
    _lastChangedAt: 1701935591744,
    __typename: "Category",
    menuItems: [
      {
        id: "593ab213-8b6a-42e7-813e-d7d430287625",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "Bagel Cinnamon Double Cheese",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: "../assets/DUNKIN/MenuItems/Bakery/(4).jpg",
        categoryID: "4f74794c-9972-4902-8a28-9f314160e7cd",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "939e342a-9666-4d9c-baa0-b1116a17b483",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "small",
            price: 100,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T18:10:12.775Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701627012798,
            __typename: "Price",
          },
        ],
        choiceGroups: [],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-12-03",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: null,
        quantityOrdered: 0,
        updatedAt: "2023-12-04T15:51:31.088Z",
        _version: 3,
        _deleted: null,
        _lastChangedAt: 1701705091125,
        __typename: "MenuItem",
      },
      {
        id: "cbc07ae1-8b5b-49bf-9a41-063044fe919d",
        conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
        kioskID: null,
        name: "Bagel Cream Cheese Oats",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: "../assets/DUNKIN/MenuItems/Bakery/(6).jpg",
        categoryID: "4f74794c-9972-4902-8a28-9f314160e7cd",
        categoryName: null,
        outOfStock: false,
        requiredPoints: null,
        symphonyID: "0",
        ratings: null,
        prices: [
          {
            id: "2f5adf04-fa84-471e-98b3-65ffe7c84bd4",
            conceptID: "65acbf07-1a9a-4dde-9605-a20f19753404",
            name: "small",
            price: 100,
            symphonyID: "0",
            deleted: "0",
            createdAt: "2023-12-03",
            createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
            createdByName: "Admin",
            updatedAt: "2023-12-03T18:09:32.801Z",
            _version: 1,
            _deleted: null,
            _lastChangedAt: 1701626972834,
            __typename: "Price",
          },
        ],
        choiceGroups: [],
        ExtraChoiceGroups: null,
        enabled: true,
        hasLimitedQuantity: false,
        quantity: 0,
        autoRestock: false,
        restockQuantity: 0,
        deleted: "0",
        createdAt: "2023-12-03",
        createdByID: "b9918037-8b54-4ddc-aaf8-4c1e6166b0ae",
        createdByName: "Admin",
        multiLanguages: null,
        quantityOrdered: 0,
        updatedAt: "2023-12-03T18:09:35.602Z",
        _version: 1,
        _deleted: null,
        _lastChangedAt: 1701626975606,
        __typename: "MenuItem",
      },
    ],
  },
];
