import { Box, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const VoucherHeader = () => {
  const images = ["./assets/DUNKIN/MenuItems/1.png", "./assets/DUNKIN/MenuItems/2.png", './assets/DUNKIN/MenuItems/3.jpg', "./assets/DUNKIN/MenuItems/4.png",  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = (currentImageIndex + 1) % images.length;
      setCurrentImageIndex(newIndex);
    }, 9000);

    return () => clearInterval(interval);
  }, [currentImageIndex, images.length]);

  const goToPreviousImage = () => {
    const newIndex = (currentImageIndex - 1 + images.length) % images.length;
    setCurrentImageIndex(newIndex);
  };

  const goToNextImage = () => {
    const newIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(newIndex);
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        position: "relative",
        borderRadius: '1rem',
      }}
    >
      <IconButton
        aria-label="Previous"
        onClick={goToPreviousImage}
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          zIndex: 1,
        }}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        aria-label="Next"
        onClick={goToNextImage}
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          right: 0,
          zIndex: 1,
        }}
      >
        <NavigateNextIcon />
      </IconButton>
      <Box
        sx={{
          width: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            style={{
              width: "100%",
              minWidth: "100%",
              display: index === currentImageIndex ? "block" : "none",
            }}
            src={image}
            alt={`image-${index}`}
          />
        ))}
      </Box>
    </Box>
  );
};

export default VoucherHeader;
