import { Grid, useTheme, Typography, Button, IconButton, TextField, InputAdornment, Box } from '@mui/material';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import getUser from "../../services/getUser";
import updateUser from '../../services/updateUser';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

interface UserData {
    id?: any;
    username?: any;
    phone_number?: any;
    birthdate?: any;
    email?: any;
    picture?: any;
    // password?: string;
    version?: any;
    address?: any;
    phones?: any;
    gender?: any;
    _version?: any;
    deleted?: any;
    createdAt?: any
}
type UserForm = {
    phone_number: string;
    birthdate: string;
    email: string;
    password: string;
};
function UserPage() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const user = useSelector((state: { user: UserData }) => state.user);
    const [userData, setUserData] = useState<UserData | null>(null);
    const [updatedUserData, setUpdatedUserData] = useState<UserData | null>(null);
    const { register, handleSubmit, setValue, formState: { errors }, trigger } = useForm<UserForm>();



    const handlePasswordVisibility = () => {
        // setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const handleInput = (fieldName: keyof UserForm, value: any) => {
        trigger(fieldName);
        setValue(fieldName, value);
    };
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const result = e.target?.result;
                if (typeof result === 'string') {
                    setSelectedImage(result);
                }
            };

            reader.readAsDataURL(file);
        }
    };
    const fetchData = async () => {
        try {
            const userID = user.id;
            const Data = await getUser(userID);
            setUserData(Data);
            console.log('response', Data)
            console.log('userData', userData)
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }
    const onSubmit = async (data: UserForm) => {

        if (userData && userData.id) {
            try {
                const updatedData = {
                    userID: userData.id,
                    version: userData._version,
                    email: data.email,
                    name: userData.username || '',
                    phone_number: data.phone_number,
                    address: userData.address || '',
                    birthdate: data.birthdate,
                    gender: userData.gender || '',
                    picture: selectedImage || userData.picture,
                    phones: userData.phones || [],
                    deleted: userData.deleted,
                    createdAt: userData.createdAt
                };

                await updateUser(
                    updatedData.userID,
                    updatedData.version,
                    updatedData.email,
                    updatedData.name,
                    updatedData.phone_number,
                    updatedData.address,
                    updatedData.birthdate,
                    updatedData.gender,
                    updatedData.picture,
                    updatedData.phones,
                    updatedData.deleted,
                    updatedData.createdAt,
                );

            } catch (error) {

                console.error('Error updating user:', error);
            }
        }
    };



    useEffect(() => {
        if(!user){
            navigate('/login')
        }
        fetchData()
    }, [user])

    return (
        <Grid>
            <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ padding: '1rem' }}
            >
                <Grid item sm={12} lg={12}>
                    <Link to="/">
                        <Button
                            sx={{
                                backgroundColor: 'rgba(169, 169, 169, 0.55)',
                                color: 'rgba(64, 64, 64, 0.7)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minWidth: ' 2rem',
                                height: '2rem',
                                borderRadius: '0.5rem',
                                marginBottom: '1rem',
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: "#ffffff",
                            }}
                        >
                            <ArrowBackIosNewIcon sx={{ color: '#ff189c', fontSize: '1rem' }} />
                        </Button>
                    </Link>
                </Grid>
                <Grid item sm={12} lg={12} xs={12}>
                    <Typography style={{
                         color: theme.palette.primary.main,
                        fontSize: 24,
                        fontWeight: '600',
                        letterSpacing: 0.96,
                    }}>
                        Profile
                    </Typography>

                </Grid>


            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* image */}
                <Grid item >
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: "center", flexDirection: 'column', alignItems: "center" }}>
                        <Box>
                            <label htmlFor="capture-button">
                                <input
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    id="capture-button"
                                    type="file"
                                    onChange={handleFileUpload}
                                />
                                <Button
                                    variant="contained"
                                    component="span"
                                    sx={{
                                        marginY: '1rem',
                                        borderColor: 'linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)',
                                        borderRadius: '50%',
                                        color: '#FFFFFF',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderWidth: '5px',
                                        borderStyle: 'solid',
                                        height: '150px',
                                        width: '150px',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '& img': {
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                        },
                                    }}
                                >

                                        <img src='./assets/DUNKIN/MenuItems/ppl.jpg' alt="Selected" />
                                </Button>


                            </label>
                        </Box>
                        <Box>
                            <Typography>{userData?.username || 'Customer'}</Typography>
                        </Box>
                    </Box>

                </Grid>



                {/* inputs */}
                <Grid item sm={12} sx={{ padding: "1.5rem" }}>

                    < Typography>
                        Mobile No.
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder=" Mobile No."
                        fullWidth
                        type='tel'
                        defaultValue={userData?.phone_number || ''}
                        {...register('phone_number', {
                            pattern: {
                                value: /^[0-9]{10,12}$/,
                                message: 'Invalid phone number format',
                            },
                        })}
                        onChange={(e) => handleInput("phone_number", e.target.value)}
                        sx={{
                            "& .MuiInputBase-root": {
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.main,
                                width: "100%",
                                margin: "0.5rem auto",
                                borderColor: "#ffffff",
                                marginBottom: "1rem",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            },
                        }}
                    />
                    {errors.phone_number && (
                        <span style={{ color: 'red' }}>{errors.phone_number.message}</span>
                    )}

                    < Typography>
                        Birth No.
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder=" Birth No."
                        fullWidth
                        type='date'
                        // value={userData?.birthdate || ''}
                        {...register('birthdate', {
                            pattern: {
                                value: /^\d{4}-\d{2}-\d{2}$/,
                                message: 'Invalid date format (YYYY-MM-DD)',
                            },
                        })}
                        onChange={(e) => handleInput("birthdate", e.target.value)}
                        sx={{
                            "& .MuiInputBase-root": {
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.main,
                                width: "100%",
                                margin: "0.5rem auto",
                                borderColor: "#ffffff",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                            },
                        }}
                    />
                    {errors.birthdate && (
                        <span style={{ color: 'red' }}>{errors.birthdate.message}</span>
                    )}

                    < Typography>
                        Email
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Email"
                        fullWidth
                        type='email'
                        // value={userData?.email || ''}
                        {...register("email", {
                            required: "email is required",
                            pattern: {
                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/,
                                message: "Invalid email format",
                            },
                        })}
                        onChange={(e) => handleInput("email", e.target.value)}
                        sx={{
                            "& .MuiInputBase-root": {
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.main,
                                width: "100%",
                                margin: "0.5rem auto",
                                borderColor: "#ffffff",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                                marginBottom: "1rem",
                            },
                        }}
                    />
                    {errors.email && (
                        <span style={{ color: "red" }}>{errors.email.message}</span>
                    )}
                    < Typography>
                        Password
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.main,
                                transition: 'box-shadow 0.3s',
                                borderRadius: '1rem',
                                padding: '0.4rem',
                                minWidth: "30px",
                                marginLeft: "1rem",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

                                '&:hover': {
                                    boxShadow: '0px 0px 5px 2px rgba(240, 126, 170, 0.7)',
                                    backgroundColor: theme.palette.primary.dark,
                                },
                            }}
                        >
                            <Link to="/Password" style={{
                                textDecoration: 'none', color: theme.palette.primary.light, cursor: 'pointer',
                            }} >
                                <Typography
                                    style={{
                                        color: theme.palette.primary.main,
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: "0.7rem",
                                        backgroundColor: theme.palette.primary.dark,
                                    }}>
                                    <img src="assets/edit.png" alt="edit" style={{
                                        marginRight: '0.5rem',
                                        width: '18px',
                                        height: '18px',
                                        verticalAlign: 'middle'
                                    }} /> Change
                                </Typography>
                            </Link>
                        </Button>

                    </Typography>
                    <TextField
                        id="outlined-basic"
                        placeholder="*******"
                        variant="outlined"
                        disabled
                        fullWidth
                        // type={showPassword ? "text" : "password"}
                        // value={userData?.password || ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handlePasswordVisibility} sx={{ color: theme.palette.primary.main }}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            "& .MuiInputBase-root": {
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.primary.dark,
                                width: "100%",
                                margin: "0.5rem auto",
                                borderColor: "#ffffff",
                                marginBottom: "1rem",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            },
                        }}
                    />

                    <Button variant="contained" type="submit"
                        sx={{
                            marginY: "2rem",
                            fontSize: 22,
                            width: '100%',
                            color: '#FFFFFF',
                            background:' linear-gradient(90deg, rgba(255,114,0,1) 0%, rgba(255,114,0,1) 46%, rgba(255,24,156,1) 85%, rgba(255,24,156,1) 100%)',

                            boxShadow: 'none',
                            transition: 'box-shadow 0.3s',
                            borderRadius: '1rem',
                            padding: "1rem",
                            '&:hover': {
                                boxShadow: '0px 0px 5px 2px rgb(240,126,165,0.7)',
                                background:' linear-gradient(90deg, rgba(255,114,0,1) 0%, rgba(255,114,0,1) 46%, rgba(255,24,156,1) 85%, rgba(255,24,156,1) 100%)',
                            },
                        }}>
                        Save Changes
                    </Button>


                </Grid>
            </form >



        </Grid >




    );
}


export default UserPage
