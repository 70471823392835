import { useTheme } from "@mui/material/styles";
import { Typography, Card } from "@mui/material";
import CounterComponent from "../countercomponent/CounterComponent";
import { capitalization } from "../../constants/helperFunction";
import { Box } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { setSelectedItem } from "../../state";
import { useDispatch } from "react-redux";

interface FoodCardProps {
  image: string;
  name: string;
  description: string;
  newItem: Boolean;
  prices: { price: number }[];
  item: any;
  categoryID: string;
}

const FoodCard = ({
  image,
  name,
  description,
  newItem,
  prices,
  item,
  categoryID,
}: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultImage = "assets/image19.png";
  const price = prices.length > 0 ? prices[0].price : 0;
  const navigateToMenuItem = () => {
    dispatch(setSelectedItem(item));
    navigate(`/menu/${categoryID}/${item.id}`);
  };

  return (
    <Box
      sx={{
        padding: "4px",
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
      }}
    >
      <Card
        sx={{
          backgroundColor: theme.palette.primary.dark,
          width: "100%",
          height: "100%",
          borderRadius: "20px",
        }}
      >
        {/*main container*/}
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          {/*image container*/}
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{
              position: "relative",
              backgroundColor: "white",
              width: "100%",
              borderRadius: "20px",
              overflow: "hidden",
              border: "0.1rem solid #ff189c",
            }}
          >
            <img
              style={{
                objectFit: "contain",
                width: "100%",
                aspectRatio: "2/1",
              }}
              onClick={navigateToMenuItem}
              src={item.image}
              alt={name}
            />
            {newItem && (
              <Typography
                variant="subtitle1"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "#ff189c",
                  color: "#23222F",
                  padding: "5px 18px",
                  borderRadius: "0px 14.543px",
                  fontSize: "13.573px",
                  fontWeight: "600",
                }}
                onClick={navigateToMenuItem}
              >
                New
              </Typography>
            )}
          </Box>
          {/*info container*/}
          <Box sx={{ padding: "0px 3px", paddingBottom: "5px" }}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              sx={{ padding: "10px 5px" }}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                onClick={navigateToMenuItem}
              >
                <Typography
                  variant="h6"
                  color={theme.palette.primary.main}
                  fontSize={"13px"}
                  fontWeight={"700"}
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 1, // Number of lines you want to display
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal"
                  }}
                >
                  {capitalization(item.name)}
                </Typography>
              </Box>
              <Typography
                variant="h6"
                color={"#9B9B9B"}
                fontSize={"9px"}
                fontWeight={"400"}
              >
                {description === "" ? "." : description}
              </Typography>
            </Box>

            {/*price container*/}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ marginRight: ".5rem", marginTop: "auto" }}
            >
              <Typography
                variant="h6"
                color={theme.palette.primary.main}
                fontSize={"13px"}
                fontWeight={"700"}
                paddingLeft={".5rem"}
              >
                {price} SAR
              </Typography>
              <CounterComponent
                itemID={item.id}
                categoryID={categoryID}
                item={item}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default FoodCard;
