import { Grid, useTheme, Typography, Button, IconButton, TextField, InputAdornment } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useState } from 'react';

function NewAddress() {
  const theme = useTheme();
  const [showTextFields, setShowTextFields] = useState(false);
  const handleButtonClick = () => {
    setShowTextFields(!showTextFields);
  };
  return (
    <Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '1rem' }}
      >
        <Grid item sm={12} lg={12}>
          <Link to="/">
            <Button
              sx={{
                backgroundColor: 'rgba(169, 169, 169, 0.55)',
                color: 'rgba(64, 64, 64, 0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: ' 2rem',
                height: '2rem',
                borderRadius: '0.5rem',
                marginBottom: '1rem',
              }}
            >
              <ArrowBackIosNewIcon sx={{ color: '#ff189c', fontSize: '1rem' }} />
            </Button>
          </Link>
        </Grid>
        <Grid item sm={12} lg={12} xs={12}>
          <Typography style={{
            color: 'white',
            fontSize: 24,
            fontWeight: '600',
            letterSpacing: 0.96,
          }}>
            New Address
          </Typography>

        </Grid>

        {/* //the address */}
        <Grid container item alignItems='center' sm={12} sx={{ marginY: "1rem", backgroundColor: theme.palette.primary.dark, padding: "0.5rem", borderRadius: "0.5rem" }}>
          <Grid sm={2} xs={2}>
            <IconButton
              sx={{ backgroundColor: "#ff189c", }}
            >
              <LocationOnIcon sx={{ color: "black" }} />
            </IconButton>
          </Grid>
          <Grid sm={7} xs={7}>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: 14,
              }}
            >
              Area
            </Typography>
            <Typography
              sx={{
                color: '#9D9D9D',
                fontSize: 10,
              }}
            >
              Street 261, New Maadi
            </Typography>
          </Grid>
          <Grid sm={2} xs={2}>
            <Button sx={{ color: '#ff189c', fontSize: 12 }} onClick={handleButtonClick}>
              change
            </Button>

          </Grid>
        </Grid>
        {showTextFields && (
          <Grid container item sm={12} sx={{}}>

            <Grid item sm={12} xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Building Name"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.main,
                    width: "100%",
                    margin: "0.5rem auto",
                    borderColor: "#ffffff",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                    marginBottom: "1rem",
                  },
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Appartment / Floor No."
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.main,
                    width: "100%",
                    margin: "0.5rem auto",
                    borderColor: "#ffffff",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                    marginBottom: "1rem",
                  },
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Street Name/No. "
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.main,
                    width: "100%",
                    margin: "0.5rem auto",
                    borderColor: "#ffffff",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                    marginBottom: "1rem",
                  },
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Phone Number "
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.main,
                    width: "100%",
                    margin: "0.5rem auto",
                    borderColor: "#ffffff",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                    marginBottom: "1rem",
                  },
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Address Label (e.g. Home)"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.main,
                    width: "100%",
                    margin: "0.5rem auto",
                    borderColor: "#ffffff",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                    marginBottom: "1rem",
                  },
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Additional Directions (Optional)"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.main,
                    width: "100%",
                    margin: "0.5rem auto",
                    borderColor: "#ffffff",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                    marginBottom: "1rem",
                  },
                }}
              />
            </Grid>




            <Button variant="contained" type="submit"
              sx={{
                marginY: "2rem",
                fontSize: 22,
                width: '100%',
                color: '#FFFFFF',
                background: "#ff189c",
                boxShadow: 'none',
                transition: 'box-shadow 0.3s',
                borderRadius: '1rem',
                padding: "1rem",
                '&:hover': {
                  boxShadow: '0px 0px 5px 2px rgb(240,126,165,0.7)',
                  background: "#ff189c",
                },
              }}>
              Save Address
            </Button>
          </Grid>
        )}


      </Grid >

    </Grid>
  )
}

export default NewAddress
