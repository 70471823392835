import { IconButton, Badge, useTheme, Grid } from "@mui/material";
import {
  DarkMode,
  Home,
  ShoppingCart,
  PersonOutline,
  LightMode,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "../../state/index";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function Navbar() {
  const totalQuantity = useSelector((state: any) => state.cart.quantity || []);
  const dispatch = useDispatch(); //dispatch reducer action
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const isHomePage = location.pathname === "/";
  const isCartPage = location.pathname === "/cart";
  const isProfilePage = location.pathname === "/Profile";
  // const [activeButton, setActiveButton] = useState(null);

  // const handleButtonClick = (buttonName: any) => {
  //   setActiveButton(buttonName);
  // };
  const navigateToHome = () => {
    navigate("/");
  };
  const navigateTocart = () => {
    navigate("/cart");
  };
  const navigateToProfile = () => {
    navigate("/Profile");
  };
  return (
    <Grid
      container
      justifyContent="space-around"
      sx={{
        position: "fixed",
        paddingY: "0.3rem",
        paddingLeft: "0.5rem",
        marginTop: "3rem",
        bottom: 0,
        width: "100%",
        backgroundColor: theme.palette.primary.dark,
        zIndex: 1000,
        boxShadow: "0 6rem 7rem rgba(0, 0, 0, 0.75)",
      }}
    >
      <Grid item sm={2} xs={2}>
        <IconButton
          onClick={() => dispatch(setMode())}
          sx={{
            color: "#9B9B9B",
            boxShadow: "none",
            transition: "box-shadow 0.3s",
            fontSize: "25px",
            "&:hover": {
              boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
              backgroundColor: "#ff6e0c",
            },
          }}
        >
          {theme.palette.mode === "dark" ? (
            <DarkMode sx={{ fontSize: "25px", margin: "0" }} />
          ) : (
            <LightMode sx={{ fontSize: "25px" }} />
          )}
        </IconButton>
      </Grid>
      <Grid item sm={2} xs={2}>
        <IconButton
          sx={{
            color: "#9B9B9B",
            boxShadow: "none",
            transition: "box-shadow 0.3s",
            backgroundColor: isHomePage ? "#ff6e0c" : "transparent",
            fontSize: "25px",
            "&:hover": {
              boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
              backgroundColor: isHomePage ? "#ff6e0c" : "transparent",
            },
          }}
          onClick={() => {
            navigateToHome();
          }}
        >
          <Home sx={{ fontSize: "25px" }} />
        </IconButton>
      </Grid>
      <Grid item sm={2} xs={2}>
        <IconButton
          onClick={() => {
            navigateTocart();
          }}
          sx={{
            color: "#9B9B9B",
            boxShadow: "none",
            transition: "box-shadow 0.3s",
            backgroundColor: isCartPage ? "#ff6e0c" : "transparent",
            fontSize: "25px",
            "&:hover": {
              boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
              backgroundColor: isCartPage ? "#ff6e0c" : "transparent",
            },
          }}
        >
          {totalQuantity > 0 ? (
            <Badge badgeContent={totalQuantity} color="primary">
              <ShoppingCart sx={{ fontSize: "25px" }} />
            </Badge>
          ) : (
            <ShoppingCart sx={{ fontSize: "25px" }} />
          )}
        </IconButton>
      </Grid>

      <Grid item sm={2} xs={2}>
        <IconButton
          onClick={() => {
            navigateToProfile();
          }}
          sx={{
            color: "#9B9B9B",
            boxShadow: "none",
            transition: "box-shadow 0.3s",
            backgroundColor: isProfilePage ? "#ff6e0c" : "transparent",
            fontSize: "25px",
            "&:hover": {
              boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
              backgroundColor: isProfilePage ? "#ff6e0c" : "transparent",
            },
          }}
        >
          <PersonOutline sx={{ fontSize: "25px" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default Navbar;
