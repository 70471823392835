import axios from "axios";
import { EndPoints } from "../constants/Enums";

type RequestData = {
  [key: string]: any; 
};

async function fetchData(operationId: number, requestData: RequestData = {}) {
  try {
    console.log(operationId);
    const requestBody = { operationId, ...requestData };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    const response = await axios.post(EndPoints.getCategoryData, requestBody);
    return response.data;

  } catch (error) {
    console.error(
      `Error fetching data for operation ID ${operationId}:`,
      error
    );
    throw error;
  }
}

export async function getCategoryData() {
  const operationId = 1;
  return await fetchData(operationId);
}

export async function getCart() {
  const operationId = 2;
  return await fetchData(operationId);
}

export async function getConcept(conceptID: string) {
  const operationId = 11;
  return await fetchData(operationId, { conceptID });
}

export async function getMainCategories() {
  const operationId = 7;
  return await fetchData(operationId);
}

export async function getMenuItems() {
  const operationId = 3;
  return await fetchData(operationId);
}

export async function getSubCategories() {
  const operationId = 8;
  return await fetchData(operationId);
}

// export async function getUserOrders() {
//   const operationId = 6;
//   return await fetchData(operationId);
// }
